import { combineReducers } from "redux";

// Application Reducers 
import { alerts } from "./alerts";
import { login } from "./login";
//import { users } from "./users";
import menu from "../toolkit/features/menu";
import deleteConfirmModal from "../toolkit/features/modals/deleteConfirmModal";

// Page Reducers
import company_state from "../toolkit/features/company_state";
import innovation_state from "../toolkit/features/innovation_state";
import organization_state from "../toolkit/features/organization_state";
import roadmaps_state from "../toolkit/features/roadmaps_state";
import sprints_state from "../toolkit/features/sprints_state";
import success_state from "../toolkit/features/success_state";

//
// Data Reducers
//

// Company Reducers
import company from "../toolkit/features/company/company";
import values from "../toolkit/features/company/values";
import facts from "../toolkit/features/company/facts";
import timelines from "../toolkit/features/company/timelines";

//Success Reducers
import success from "../toolkit/features/success/success";
import successyear from "../toolkit/features/success/successyear";
import roadmaplinks from "../toolkit/features/success/successroadmaplinks";
import innovationlinks from "../toolkit/features/success/successinnovationlinks";
import sprintlinks from "../toolkit/features/success/successsprintlinks";
import baseline from "../toolkit/features/success/baseline";
import baselinedelta from "../toolkit/features/success/baselinedelta";

// Roadmap Reducers
import roadmapcells from "../toolkit/features/roadmapcells";
import sections from "../toolkit/features/roadmapsection";
import roadmap from "../toolkit/features/roadmaps/roadmap";
import roadmaprows from "../toolkit/features/roadmaprows";
import roadmapgrowth from "../toolkit/features/roadmapgrowth";
import roadmapcharts from "../toolkit/features/roadmapcharts";
import roadmapchartrows from "../toolkit/features/roadmapchartrows";
import roadmapcagr from "../toolkit/features/roadmapcagr";

// Sprint Reducers
import sprints from "../toolkit/features/sprints/sprints";
import sprintcycle from "../toolkit/features/sprintcycle";
import sponsors from "../toolkit/features/sprints/sponsors";
import comments from "../toolkit/features/sprints/comments";
import sprintinvestment from "../toolkit/features/sprints/sprintinvestment";
import sprintinvestmentcell from "../toolkit/features/sprints/sprintinvestmentcell";

// Innovation Reducers
import ideas from "../toolkit/features/innovation/ideas";
import themes from "../toolkit/features/innovation/themes";
import actionlink from "../toolkit/features/innovation/actionlink";

// Organiization Reducers
import organization from "../toolkit/features/organization/organization";
import persons from "../toolkit/features/organization/persons";
import productivity from "../toolkit/features/organization/productivity";
import roles from "../toolkit/features/roles";
import rolecompetencies from "../toolkit/features/rolecompetencies";
import competencyscores from "../toolkit/features/organization/competencyscores";
import roleperson from "../toolkit/features/roleperson";
import positionparent from "../toolkit/features/organization/positionparent";
import positionperson from "../toolkit/features/organization/positionperson";
import roleview from "../toolkit/features/organization/roleview";
import personsview from "../toolkit/features/organization/personview";
import competencies from "../toolkit/features/competencies";
import overallscores from "../toolkit/features/organization/overallscores";
import metrics from "../toolkit/features/organization/metrics";
import compensationindex from "../toolkit/features/organization/compensationindex";
import localcurrency from "../toolkit/features/organization/localcurrency";

// Stakeholders
import stakeholders from "../toolkit/features/stakeholders";
import categories from "../toolkit/features/categories";
import matrices from "../toolkit/features/matrices";

// Tenants
import tenants from "../toolkit/features/tenants";
import users from "../toolkit/features/tenants/users";
import currentuser from "../toolkit/features/tenants/currentuser";
import fx from "../toolkit/features/tenants/fx";
import fxrow from "../toolkit/features/tenants/fxrow";

// Legacy Reducers - to be cleaned up
import { aboutModal } from "./aboutModal";
import { menuModal } from "./menuModal";
import { positionModal } from "./positionModal";
import { newtaskModal } from "./newtaskModal";
import { newcommentModal } from "./newcommentModal";
import { titleModal } from "./titleModal";
import { businessunitModal } from "./businessunitsModal";
import { helpModal } from "./helpModal";
import { people } from "./people";
import stakeholderModal from "../toolkit/features/modals/stakeholderModal";
import matrixModal from "../toolkit/features/modals/matrixModal";
import categoryModal from "../toolkit/features/modals/categoryModal";
import taskModal from "../toolkit/features/modals/taskModal";
import roadmapsectionModal from "../toolkit/features/modals/roadmapsectionModal";
import companyheroModal from "../toolkit/features/modals/companyheroModal";
import purposeModal from "../toolkit/features/modals/purposeModal";
import quoteModal from "../toolkit/features/modals/quoteModal";
import colors from "../toolkit/features/colors";
import roadmapSectionsModal from "../toolkit/features/roadmaps/sectionsModal";
import roadmapChartModal from "../toolkit/features/roadmaps/chartDetailModal";
import roadmapTimelineModal from "../toolkit/features/roadmaps/timelineDetailModal";
import roadmapRowModal from "../toolkit/features/roadmaps/rowDetailModal";


const rootReducer = combineReducers({
  // Application Reducers
  alerts,
  login,
  users,
  tenants,
  menu,
  // Page Reducers
  company_state,
  innovation_state,
  organization_state,
  roadmaps_state,
  sprints_state,
  success_state,
  // Data Reducers 
  comments,
  values,
  competencies,
  people,
  sections,
  company,
  ideas,
  timelines,
  facts,
  sprints,
  roles,
  sponsors,
  sprintcycle,
  success,
  successyear,
  themes,
  organization,
  aboutModal,
  menuModal,
  positionModal,
  newtaskModal,
  newcommentModal,
  titleModal,
  businessunitModal,
  helpModal,
  stakeholders,
  categories,
  matrices,
  stakeholderModal,
  matrixModal,
  categoryModal,
  baseline,
  baselinedelta,
  taskModal,
  roadmapsectionModal,
  companyheroModal,
  purposeModal,
  quoteModal,
  deleteConfirmModal,
  colors,
  roadmap,
  roadmapSectionsModal,
  roadmapChartModal,
  roadmapTimelineModal,
  roadmapRowModal,
  roadmapcells,
  persons,
  rolecompetencies,
  roleperson,
  competencyscores, 
  roadmaprows,
  roadmapgrowth,
  roadmapcagr,
  roadmapcharts,
  roadmapchartrows,
  roadmaplinks,
  innovationlinks,
  sprintlinks,
  actionlink,
  productivity,
  positionparent,
  positionperson,
  roleview,
  personsview,
  sprintinvestment,
  sprintinvestmentcell,
  overallscores, 
  metrics,
  compensationindex, 
  fx, 
  fxrow,
  localcurrency,
  currentuser

});

export default rootReducer;
