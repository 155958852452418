import React, {Fragment, useEffect} from "react";
import {SprintList} from "./SuccessSprintList";
import {filter} from "lodash"

function SuccessSprintActions(props) {
    const { linkedsprints, success_id } = props; 
  
    if (!linkedsprints || !success_id) {
        return null;
    }

    const linkedsprintsfiltered = filter(linkedsprints, {success: success_id});

    return (
        
        <Fragment>
            <SprintList
                linkedsprintsfiltered = {linkedsprintsfiltered}    
            />
        </Fragment>
    )
}

export { SuccessSprintActions }