import "regenerator-runtime/runtime.js";
import axios from "axios";
import { authConfig } from "./helpers";
import BaseService from "./BaseService";

class OrganizationRolesService extends BaseService {
  listUrl() {
    return `${this.baseUrl}/orgsummary`;
  }

  async getData() {
    const rolesview = await this.getAll();
    return rolesview.results;
  }

  // async getOrgChartData(year) {
  //   const url = this.listUrl() + "chart";
  //   const config = authConfig();
  //   config.params = { startyear: year };
  //   const response = await axios.get(url, config);
  //   return response.data;
  // }
}

export const organizationRolesService = new OrganizationRolesService();
