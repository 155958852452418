import React, {Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllRoadmaplinks } from "./../../../redux/toolkit/features/success/successroadmaplinks";
import { fetchAllSections } from "./../../../redux/toolkit/features/roadmapsection";
import {filter} from 'lodash'
import { openDeleteModal } from "./../../../redux/toolkit/features/modals/deleteConfirmModal";
import { fetchAllRows } from '../../../redux/toolkit/features/roadmaprows';
import success from '../../../redux/toolkit/features/success/success';


function RenderRoadmaps() {
    const linkedroadmaps = Object.values(useSelector((state) =>  state.roadmaplinks.entities));
    const roadmaprows = Object.values(useSelector((state) =>  state.roadmaprows.entities))
    const roadmapsections = Object.values(useSelector((state) =>  state.sections.entities))
    const successId = useSelector((state) => state.success_state.successBaseId)
    const dispatch = useDispatch();
   
    async function fetchRoadmaplinksdata() {
        dispatch(fetchAllRoadmaplinks());
        dispatch(fetchAllSections());
        dispatch(fetchAllRows());
    }

    useEffect(() => {
        fetchRoadmaplinksdata();
      }, []);

    var filteredlinkedroadmaps = filter(linkedroadmaps, {success:successId})

    function renderRow(row) {
        let sectionname = filter(roadmapsections, {pk:row.section_id})
        let rowname = filter(roadmaprows, {pk:row.row_id}) 

        return (
        <tr className="ml-4 pl-5" colSpan="2" style={{fontSize:"15px"}}>
            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderLeft:"solid 1px gray", borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px'}}>
            Section: {sectionname[0]?sectionname[0].name:""} <br/> Row: {rowname[0]?rowname[0].name:""}
            </td>        
            <td style={{borderTop:"solid 1px grey", borderBottom:"solid 1px gray", borderRight:"solid 1px gray", width:"25px", borderTopRightRadius: '5px', borderBottomRightRadius: '5px',}} onClick={async () => {
                        dispatch(openDeleteModal({message: "Roadmap",recordpk: row.pk, recordtype: 'roadmap'}));
            }}>
            <a><button className="far fa-trash-alt" style={{color:"gray"}}/></a>
            </td>
        </tr>
        )
    }

    return (
        <Fragment>
        <table className="table is-fullwidth" style={{borderCollapse: "separate", borderSpacing: "0 10px"}}>
            <tbody>
                {filteredlinkedroadmaps.map(row => (
                    <Fragment key={"Timeline_Modal_fragment_"+row.pk}>
                        {renderRow(row)}
                    </Fragment>
                ))}
            </tbody>
        </table>
    </Fragment>
    );
}

export {RenderRoadmaps};
