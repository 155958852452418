import React, {Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
//import "bulma/css/bulma.css";
import { closeRowNoteModal, updateRows } from "../../../redux/toolkit/features/roadmaprows";

function PopupModal() {
    const showModal = useSelector((state) => state.roadmaprows.isOpen);
    const modaltitle = useSelector((state) => state.roadmaprows.rowname);
    const rowpk = useSelector((state) => state.roadmaprows.rowpk);
    const sectionpk = useSelector((state) => state.roadmaprows.section);
    const descriptionvalue = useSelector((state) => state.roadmaprows.note);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const [editStatus, setEditStatus] = useState(false);
    const [description, setDescription] = useState("");
    const dispatch = useDispatch();

    let modalClass = "modal";
    if (showModal) {
        modalClass += " is-active";
    }
   
    useEffect(() => {
            setDescription(descriptionvalue)
      }, [descriptionvalue])

    function handleChangeNote(event) {
        setDescription(event.target.value);
    }

    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                        <div className="modal-card" key="modalCard" style={{border:"solid 1px gray", borderRadius:"8px"}}>
                    <header className="modal-card-head m-0 p-3 is-pulled-right" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className='modal-card-title pl-2' style={{fontSize:"17px", color: textcolor}}>{modaltitle?modaltitle.toUpperCase():null}</p>
                        <button className="delete" aria-label="close" onClick={() => {
                            dispatch(closeRowNoteModal())
                            setEditStatus(false)
                            }}>
                            {" "}
                        </button>
                    </header>
                <section className="modal-card-body" key="modalBody">
                    {!editStatus && 
                        <p>{description}</p>
                    }
                    {editStatus && 
                        <textarea 
                            className="textarea"
                            rows="6"
                            value={description}
                            onChange={handleChangeNote}
                        />
                    }


                </section>
                <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"50px"}}>
                    {!editStatus && 
                    <button className="button is-small is-right" key="cancel" onClick={() => setEditStatus(true)} style={{borderRadius:"4px", backgroundColor: backgroundcolor, color: textcolor}}>
                       Edit
                    </button>
                    }
                    {editStatus && 
                    <Fragment>
                        <nav className='level'>
                            <div className='level-left'>
                                <div className='level-item'>

                                    <button className="button is-success is-small" key="cancel" style={{borderRadius:"4px"}}
                                        onClick={async () => {
                                            dispatch(updateRows({id: rowpk, description: description, name: modaltitle, section: sectionpk}))
                                            .unwrap()
                                            .then(() => {
                                                dispatch(closeRowNoteModal())
                                                setEditStatus(false)
                                            })
                                        }}
                                        >
                                        Save
                                    </button>
                                </div>

                                <div className='level-item'>

                                    <button className="button is-small" key="cancel" onClick={() => {
                                        dispatch(closeRowNoteModal())
                                        setEditStatus(false)
                                    }} style={{borderRadius:"4px"}}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            <div className='level-right'>
                                <div className='level-item'>
                                    <button className="button is-small is-danger" style={{borderRadius:"4px"}} key="delete" onClick={() => {
                                        dispatch(updateRows({id: rowpk, description: null, name: modaltitle, section: sectionpk}))
                                        dispatch(closeRowNoteModal())
                                    }} >
                                        Delete Note
                                    </button>
                                </div>
                            </div>
                        </nav>
                    </Fragment>
                    }
                </footer>
             </div>
        </div>

    </Fragment>
    );
}

export default PopupModal;
