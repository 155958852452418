import React, {useState, useEffect} from "react";
import { useDispatch } from 'react-redux'
import {filter} from "lodash"
import { fetchAllSprintinvestmentcell, updateSprintinvestmentcell, createSprintinvestmentcell } from "../../../../redux/toolkit/features/sprints/sprintinvestmentcell";


function SprintInvestmentCell(props) {
    const {cells, year, investmentrowPK} = props;
    const [cellvalue, setCellValue] = useState(null)
    const dispatch = useDispatch();
    var filteredcell = filter(cells, {year:year, sprintinvestment:investmentrowPK})

    useEffect(() => {
        setCellValue(filteredcell[0]?filteredcell[0].value:0);
    },[])

    async function commitSprintInvestmentcell(value) {
        if (filteredcell.length===0) {
            await dispatch(createSprintinvestmentcell({value: value , year: year, sprintinvestment: investmentrowPK}))
            .unwrap()
                dispatch(fetchAllSprintinvestmentcell());
        } 
        if (filteredcell.length!==0) {
            await dispatch(updateSprintinvestmentcell({id: filteredcell[0].pk, value: value , year: year, sprintinvestment: investmentrowPK}))
            .unwrap()
                dispatch(fetchAllSprintinvestmentcell());
        }
    }


    function renderCells() {
        const cellinputs = [];
        cellinputs.push(
            <td className="has-text-centered"><input type="number" value={cellvalue} style={{textAlign: "center"}} 
                onChange={(e) => setCellValue(e.target.value)} 
                onKeyDown={(e) => e.key === 'Enter' ? commitSprintInvestmentcell(e.target.value):null}
            />
            </td>
        );
        return cellinputs
    }

    return renderCells()
}

export { SprintInvestmentCell }
