import React from "react";
import { UserDetailForm } from "./users/UserDetailForm";
import { useDispatch, useSelector } from "react-redux";
import { usersActions } from "../redux/actions/users";

function Profile() {
  const currentUser = useSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();

  function refresh() {
    dispatch(usersActions.getCurrentUser());
  }

  return (
      <main className="has-navbar-fixed-top" style={{marginTop: "3.5em"}}>
      <section style={{paddingLeft: "10%", paddingRight: "10%", marginTop: "2em"}}>
              <nav className="level pb-0 mb-0 pt-5">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "32px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        MY PROFILE <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </nav>
                    
                    <hr className="pt-0 mt-0 pb-0 mb-5" style={{borderTop: "2px solid lightgrey"}} />

      <UserDetailForm user={currentUser} onSave={refresh} />
      </section>
    </main>
  );
}

export { Profile };
