import React, {Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../common/css/dot.css";
import {filter} from "lodash"
import { openRolesModal } from "./../../redux/toolkit/features/organization_state";
import { openDeleteModal } from "../../redux/toolkit/features/modals/deleteConfirmModal";


function RolesDetail(props) {
    const {row, personview} = props;
    const [isExpanded, setIsExpanded] = useState(false)
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const dispatch = useDispatch();

    var incumbent = personview.filter(person => person.position_id===row.pk && (person.startdate === null || new Date(person.startdate) <= new Date()) && (person.enddate === null || new Date(person.enddate) > new Date()))
    console.log("Incumbent,", incumbent)

    return (
        <Fragment>
             <tr>
             <td className="pl-0">{row.title}</td>
             <td style={{textAlign:'center'}}>{incumbent[0]?incumbent[0].firstname + ' '+ incumbent[0].secondname:'Open Position'}</td>
             <td style={{textAlign:'center'}}>{row.startdate!==null?row.startdate:'-'}</td>
             <td style={{textAlign:'center'}}>{row.enddate!==null?row.enddate:'-'}</td>
             <td style={{textAlign:'center'}}><button className="far fa-plus-square" style={{fontSize:'smaller'}}></button> Total: 434,322</td>
             <td style={{textAlign:'center'}}><button id="target" className="fas fa-list"/></td>
             <td style={{textAlign:'center'}}>{row.issuccession && <i style={{fontSize:'smaller', color:'rgb(100,100,100)'}} className="fas fa-check"></i>}</td>             
             <td style={{width:'8px', textAlign:'center'}} className="ml-0 mr-0 pl-0 pr-0"><a className="ml-0 mr-0 pl-0 pr-0" onClick={() => dispatch(openRolesModal({roleId:row.pk}))}><i className="far fa-edit mt-1 p-0" style={{color:backgroundcolor}} title="Edit Role"></i></a></td>
             <td style={{width:'8px', textAlign:'center'}} className="ml-0 mr-0 pl-0 pr-0"><a><i className="far fa-clone ml-0 mr-0 pl-0 pr-0" style={{color:backgroundcolor}} title="Clone Role"></i></a></td>
             <td style={{width:'8px', textAlign:'center'}} className="mr-0 ml-0 pl-0 pr-0"><a className="ml-0 mr-0 pl-0 pr-0" onClick={() => dispatch(openDeleteModal({message: row.title,recordpk: row.pk, recordtype: 'role'}))}><i className="far fa-trash-alt mt-1 ml-0 mr-0 pl-0 pr-0" style={{color:backgroundcolor}} title="Delete Role"/></a></td>
             </tr>

        </Fragment>
    )
}

export {RolesDetail};