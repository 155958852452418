import React, { useEffect, Fragment, useState, useContext } from "react";
import { Chart } from "react-google-charts";
import { useSelector } from "react-redux";
//import { sectionsService } from "../../services/SectionsService";
import {filter} from "lodash"
//import { useForm } from 'react-hook-form'
//import chartdataexport from "./Chartvalues";
//import { GanttComponent, TimelineViewMode, Inject, Selection, Sort, DayMarkers, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-gantt';


function GanttChart(props) {
    const { sectionpk, gantt } = props;
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    if (!gantt) {
        return null
    }

    let ganttrows=filter(gantt.results,{section: sectionpk}) 


    function ganttchart() {
        let data=[[
                { type: 'string', label: 'Task ID' },
                { type: 'string', label: 'Task Name' },
                { type: 'date', label: 'Start Date' },
                { type: 'date', label: 'End Date' },
                { type: 'number', label: 'Duration' },
                { type: 'number', label: 'Percent Complete' },
                { type: 'string', label: 'Dependencies' },
            ]]
        for (const row of ganttrows) {
            let roworder = null;
            if (row.order !== null) {
                roworder = (row.order).toString()
            }

            if (row.enddate !== null && row.startdate !== null) {
                var datarow=[roworder,row.taskname,new Date(row.startdate), new Date(row.enddate),row.duration * 86400000,row.percentcomplete, row.dependencies]
            } else if (row.enddate === null && row.startdate === null) { 
                var datarow=[roworder,row.taskname,null,null,row.duration * 86400000,row.percentcomplete, row.dependencies]
            } else if (row.enddate === null && row.startdate !== null) {
                var datarow=[roworder,row.taskname,new Date(row.startdate),,row.duration * 86400000,row.percentcomplete, row.dependencies]
            } else if (row.enddate !== null && row.startdate === null) {
                var datarow=[roworder,row.taskname,new Date(row.startdate),,row.duration * 86400000,row.percentcomplete, row.dependencies]
            }

            data.push(datarow);

         }   
    
         function getTintedColor(color, v) {
            if (color.length >6) { color= color.substring(1,color.length)}
            var rgb = parseInt(color, 16); 
            var r = Math.abs(((rgb >> 16) & 0xFF)+v); if (r>255) r=r-(r-255);
            var g = Math.abs(((rgb >> 8) & 0xFF)+v); if (g>255) g=g-(g-255);
            var b = Math.abs((rgb & 0xFF)+v); if (b>255) b=b-(b-255);
            r = Number(r < 0 || isNaN(r)) ? 0 : ((r > 255) ? 255 : r).toString(16); 
            if (r.length == 1) r = '0' + r;
            g = Number(g < 0 || isNaN(g)) ? 0 : ((g > 255) ? 255 : g).toString(16); 
            if (g.length == 1) g = '0' + g;
            b = Number(b < 0 || isNaN(b)) ? 0 : ((b > 255) ? 255 : b).toString(16); 
            if (b.length == 1) b = '0' + b;
            return "#" + r + g + b;
        } 

         var options = {
            gantt: {
              palette: [
                {
                  "color": backgroundcolor,
                  "dark": getTintedColor(backgroundcolor,40),
                  "light": backgroundcolor
                }
              ],
              labelStyle: {
                fontName: "Roboto2",
                fontSize: 14,
                color: '#757575',
              },
            }
          }

          console.log("Gantt Data", data)
            return (
                <Fragment>
                <div className="columns is-vcentered is-centered ml-0 mr-0" style={{backgroundColor: "white", minHeight:"150px", textAlign:'left'}}>
                {data.length>1 &&
                <Chart
                width={'100%'}
                height={((ganttrows.length + 1) * 42)}
                chartType="Gantt"
                loader={<div>Loading Chart</div>}
                data={data}
                options={options}
                rootProps={{ 'data-testid': '1' }}
                style={{textAlign:"left"}}
                />
                }
                {data.length===1 && <button className="button is-small"> Add Gantt Data</button>}
                </div>
                </Fragment>

            )
        }
    return (
        <Fragment>
            {ganttchart()} 
        </Fragment>
    )
}

export { GanttChart };
