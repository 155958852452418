import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { usersService } from "../services/UsersService";
import { TenantForm } from "./settings/TenantForm";
import { FXForm } from "./settings/FXForm";
import { RangeForm } from "./settings/RangeForm";
import { tenantsService } from "../services/TenantsService";



function Settings() {
  const currentUser = useSelector((state) => state.users.currentUser);
  const isAdmin = usersService.isAdmin(currentUser);
  const [tenant,setTenant] = useState(null);
  const [selectedTab, setSelectedTab] = useState("General");

  console.log("Admin", isAdmin)

  async function fetchTenant() {
    const tenant = await tenantsService.getTenant();
    setTenant(tenant);  
  }

  useEffect(() => {
      async function fetchData() {
          await fetchTenant();
      }
      fetchData();
  }, []);


  async function updateEmailOnChange(domain, key) {
    await tenantsService.patch(key, {emaildomain:domain});
    await fetchTenant();
  }


  // if (!isAdmin) {
  //   return null;
  // }

  return (
    <main className="has-navbar-fixed-top" style={{marginTop: "3.5em"}}>
    <section style={{paddingLeft: "10%", paddingRight: "10%", marginTop: "2em"}}>
            <nav className="level pb-0 mb-0 pt-5">
                      <div className="level-left">
                          <div className="level-item">
                              <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "32px", lineHeight: "22px", paddingTop: "35px"}}>
                                  <div className="pr-1">
                                      APPLICATION SETTINGS <br/><br/>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </nav>
                  
                  <hr className="pt-0 mt-0 pb-0 mb-2" style={{borderTop: "2px solid lightgrey"}} />

                  <div key="TabHeader" className="tabs ml-0 pl-0 mb-6" >
                    <ul className="pl-0 ml-0" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}>
                      <li 
                        key="OrganizationDesign" 
                        className={selectedTab === "General" ? "is-active" : ""}
                        onClick={() => setSelectedTab("General")}  
                        >           
                        <a className="ml-0 pl-0">GENERAL</a>
                      </li>
                      |
                      <li
                        key="TalentManagement" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                        className={selectedTab === 'Ranges' ? "is-active" : ""}
                        onClick={() => setSelectedTab('Ranges')}  
                        >       
                        <a>DATE RANGES</a>
                      </li>
                      |
                      <li
                        key="Metrics" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                        className={selectedTab === 'FX' ? "is-active" : ""}
                        onClick={() => setSelectedTab('FX')}  
                        >       
                        <a >FX RATES</a>
                      </li>
                    </ul>
                  </div>

          {selectedTab==="General" && <TenantForm tenant={tenant}/>}
          {selectedTab==="FX" && <FXForm tenant={tenant}/>}
          {selectedTab==="Ranges" && <RangeForm tenant={tenant}/>}


      </section>
      
    </main>
  );
}

export { Settings };
