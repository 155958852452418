import React, { Fragment, useEffect, useState, createContext } from "react";
import "./common/css/masterstyles.css";
import { useDispatch, useSelector } from "react-redux";
import { menuActions } from "../redux/actions/menu";
import { closeDeleteModal }  from "./../redux/toolkit/features/modals/deleteConfirmModal";
import { Sprint } from "./sprints/Sprint";
import {SprintHeader} from "./sprints/SprintHeader";
import SprintDateModal from "./sprints/modals/SprintDateModal";
import SprintCommentModal from "./sprints/modals/SprintCommentModal";
import SprintModal from "./sprints/modals/SprintModal";
import SprintInvestmentModal from "./sprints/modals/SprintInvestmentModal";
import SprintAddPersonModal from "./sprints/modals/SprintAddPersonModal";
import ConfirmDeleteBox from "./common/ConfirmDeleteBox";
import {personviewService} from "../services/OrganizationPersonViewService"
import {roadmapsService} from "../services/RoadmapsService";
import {filter} from "lodash"
import Spinner from "./common/Spinner";
import { setMenu } from "../redux/toolkit/features/menu";
import {fetchAllSprints, deleteSprints} from "./../redux/toolkit/features/sprints/sprints";
import { deleteComments, fetchAllComments } from "../redux/toolkit/features/sprints/comments";
import { deleteSponsors, fetchAllSponsors } from "../redux/toolkit/features/sprints/sponsors";
import { closeSprintModal } from "../redux/toolkit/features/sprints_state";
import { deleteSprintinvestment, fetchAllSprintinvestment } from "../redux/toolkit/features/sprints/sprintinvestment";
import { fetchAllRoadmap } from "../redux/toolkit/features/roadmaps/roadmap";


export const sprintdatasets = createContext();

function Sprints() {

  const sprints = Object.values(useSelector((state) => state.sprints.entities));
  const roadmap = Object.values(useSelector((state) => state.roadmap.entities));
  const sponsors = Object.values(useSelector((state) => state.sponsors.entities));
  const isCompletedSprint = useSelector((state) => state.sprints_state.isCompletedSprint)
  const isActiveSprint = useSelector((state) => state.sprints_state.isActiveSprint)
  const isDiscontinuedSprint = useSelector((state) => state.sprints_state.isDiscontinuedSprint)
  const isMyTaskSprint = useSelector((state) => state.sprints_state.isMyTaskSprint)

  const isSprintModalOpen = useSelector((state) => state.sprints_state.isSprintModalOpen)
  const isSprintDateModalOpen = useSelector((state) => state.sprints_state.isSprintDateModalOpen)
  const isSprintCommentModalOpen = useSelector((state) => state.sprints_state.isSprintCommentModalOpen)
  const isSprintInvestmentModalOpen = useSelector((state) => state.sprints_state.isSprintInvestmentModalOpen)
  const isSprintSponsorModalOpen = useSelector((state) => state.sprints_state.isSprintSponsorModalOpen)
  const showModalDelete = useSelector((state) => state.deleteConfirmModal.isOpen)

  const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);
  const recordkey = useSelector((state) => state.deleteConfirmModal.recordpk);    
  const [minYear, setMinYear] = useState(null);
  const [maxYear, setMaxYear] = useState(null);

  const dispatch = useDispatch();
  dispatch(setMenu('sprints'));
  const currentUser = useSelector((state) => state.users.currentUser);
  const [textfilter, setfilterquery] = useState('');
  const [personview, setPersonView] = useState(null);
  const [sprintModalFilteredData, setSprintModalFilteredData]= useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);

 var [filteredSprints] = [];

  async function fetchSprints() {
    dispatch(fetchAllSprints()) 
    dispatch(fetchAllSponsors())     
    dispatch(fetchAllRoadmap())
    const personview = await personviewService.getAll();
    setPersonView(personview);          
}

  useEffect(() => {
    async function fetchData() {
      setIsDataLoading(true);
        await fetchSprints();
      setIsDataLoading(false);
    }
    fetchData();

  }, []);

  useEffect(() => {
    dispatch(menuActions.setActiveMenu("sprints"));
  }, [dispatch]);

  useEffect(() => {
    setMinYear(roadmap[0]?roadmap[0].min_year:new Date().getFullYear())
    setMaxYear(roadmap[0]?roadmap[0].max_year:new Date().getFullYear()+5)
  }, [roadmap]);



//Filter States

  const handleChangeFilter = (e) => {
    setfilterquery(e.target.value)
  }

  async function onConfirmDelete(result) {
    if (result) {

        if (recordtype === 'sprints') {
            await dispatch(deleteSprints(recordkey))
                .unwrap()
                  dispatch(fetchAllSprints());
                  dispatch(closeDeleteModal());
                  dispatch(closeSprintModal());
                }
        }

        if (recordtype === 'comments') {
            await dispatch(deleteComments(recordkey))
                .unwrap()
                  dispatch(fetchAllComments());
                  dispatch(closeDeleteModal());
        }

        if (recordtype === 'sponsors') {
          await dispatch(deleteSponsors(recordkey))
              .unwrap()
                dispatch(fetchAllSponsors());
                dispatch(closeDeleteModal());
        }

        if (recordtype === 'sprintinvestment') {
          await dispatch(deleteSprintinvestment(recordkey))
              .unwrap()
                dispatch(fetchAllSprintinvestment());
                dispatch(closeDeleteModal());
        }
    }


  if (!sprints || !personview || !sponsors || !roadmap) {
    return <Spinner/>;
  }

  filteredSprints = sprints.filter((sprint) => {
    let sponsorsviewlist = filter(sponsors,{sprint_id:sprint.pk})
    var sponsorNames = sponsorsviewlist.map((sponsor) => sponsor.fullname);
    var sponsorlist = sponsorsviewlist.map((sponsor) => sponsor.fullname).join(', ')
    let sprinttitle=(sprint.title!==null?sprint.title:"HeloDavid")
    let sprintdescription=(sprint.description!==null?sprint.description:"Hellobob")
    if (sprinttitle.toLowerCase().includes(textfilter.toLowerCase()) || sprintdescription.toLowerCase().includes(textfilter.toLowerCase()) || sponsorlist.toLowerCase().includes(textfilter.toLowerCase())){
      if (isMyTaskSprint) {
        if (sponsorNames.includes(currentUser.first_name + " " + currentUser.last_name)) {

          if (isDiscontinuedSprint) {
            if (sprint.action_status === "Suspended") {
              return true;
            }
          }

          if (isCompletedSprint) {
            if (sprint.action_status === "Completed") {
              return true;
            }
          }

          if (isActiveSprint) {
            if (sprint.action_status === "Open" || sprint.action_status === "Not Started" || sprint.action_status === "In Progress") {
              return true;
            }
          }
        }
      } else 
      {
        if (isDiscontinuedSprint) {
          if (sprint.action_status === "Suspended") {
            return true;
          }
        }

        if (isCompletedSprint) {
          if (sprint.action_status === "Completed") {
            return true;
          }
        }

        if (isActiveSprint) {
          if (sprint.action_status === "Open" || sprint.action_status === "Not Started" || sprint.action_status === "In Progress") {
            return true;
          }
        }
      }
    
    };
    return filteredSprints;
    }
  );


  function rendersprintpage() {
    return (
      <sprintdatasets.Provider value={{sprints, minYear:minYear, maxYear:maxYear}}>
      <Fragment>
        <main className="has-navbar-fixed-top" style={{marginTop: "3.5em"}}>
  
          <SprintHeader 
            filteredSprints = {filteredSprints}
            handleChangeFilter = { handleChangeFilter }
          />
          <section className="pb-4" style={{paddingLeft: "12%", paddingRight: "12%", userSelect: "none"}}>
            <Sprint filteredSprints = {filteredSprints}/>
          </section>
  
          {isSprintDateModalOpen && <SprintDateModal/>}
          {isSprintCommentModalOpen && <SprintCommentModal/>}
          {isSprintModalOpen && <SprintModal
            sprints={sprints}
            sprintModalFilteredData={sprintModalFilteredData}
            personview={personview}
          />}

          {isSprintInvestmentModalOpen && <SprintInvestmentModal
            minYear={minYear}
            maxYear={maxYear}
          />}
          {isSprintSponsorModalOpen &&<SprintAddPersonModal/>}
          {showModalDelete && <ConfirmDeleteBox onConfirmDelete={onConfirmDelete}/>}
        
        </main>
      </Fragment>
      </sprintdatasets.Provider>
    )
  }

  return (
   (isDataLoading?<Spinner/>:rendersprintpage())
  );
}

export { Sprints };
