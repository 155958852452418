import React, { Fragment, useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {filter} from "lodash"
import { peopledatasets } from '../Organization';
import moment from "moment";
import { HierarchicalTree, DataBinding, DiagramComponent, StackPanel, SnapConstraints, NodeConstraints, TextElement, Node, Inject, DiagramTools, ConnectorConstraints, Rect } from "@syncfusion/ej2-react-diagrams";
import { DataManager } from "@syncfusion/ej2-data";
import OrgChartRender from './OrgChartRender'


function OrgChart(props) {
  const {roleview, personview, minYear, maxYear, basecurrency, startmonth} = props;
  const [slidervalue, setSliderValue] = useState(0);
  const [sliderdate, setSliderDate] = useState(null);  
  const [items, setItems] = useState(null);   
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const [headcount, setHeadcount] = useState(null);   

  let endmonth = null;
  let endyear = null;
  if (startmonth + 11 > 12) {
    endmonth = startmonth + 11 -12
    endyear = maxYear+1
  } else {
    endmonth = startmonth + 11
    endyear = maxYear

  }
  var date1 = new Date( startmonth + "/01/"+ minYear);
  var date2 = new Date(endmonth +"/31/"+endyear);
  var Difference_In_Days = (date2-date1) / (1000 * 3600 * 24);
  var datenew = new Date()
  var datenew = datenew.setDate(date1.getDate() + slidervalue/3650*Difference_In_Days)

  useEffect(() => {
    setSliderDate(new Date(date1.getTime() + (slidervalue/3650*Difference_In_Days*1000 * 3600 * 24)))
    var orgnodedata=[]
    function calcChartdata() {
      let orgchartdata = []
      orgchartdata.push(["ID","Position",""])
      let roleviewfiltered = filter(roleview,  o => (o.reporting_enddate === null || (new Date(o.reporting_enddate)) > sliderdate) && (o.reporting_startdate === null || (new Date(o.reporting_startdate)) <= sliderdate) && (o.role_startdate === null || (new Date(o.role_startdate)) <= sliderdate) && (o.role_enddate === null || (new Date(o.role_enddate)) > sliderdate) )
      setHeadcount(roleviewfiltered.length)


      for (const row of roleviewfiltered) {
        let orgchartname = filter(personview,o => o.position_id === row.role_id && ((o.enddate === null || (new Date(o.enddate)) > sliderdate) && (o.startdate === null || (new Date(o.startdate)) <= sliderdate) && (o.startdate === null || (new Date(o.startdate)) <= sliderdate) && (o.enddate === null || (new Date(o.enddate)) > sliderdate) ));
        console.log("Org Chart Person View:",orgchartname,personview)
        var nodeobj = {};
        if (row.parent_id === null) {
          nodeobj['Id']=row.role_id;
          nodeobj['Role']=row.role
          if (orgchartname.length !== 0){
            nodeobj['Name']=(orgchartname[0].firstname + ' ' + orgchartname[0].secondname)
          } else {
            nodeobj['Name']='Open Position';
          }
        }else {
          nodeobj['Id']=row.role_id;
          nodeobj['Role']=row.role
          nodeobj['Team']=row.parent_id
          if (orgchartname.length !== 0){
            nodeobj['Name']=(orgchartname[0].firstname + ' ' + orgchartname[0].secondname)
          } else {
            nodeobj['Name']='Open Position';
          }
        }
        orgnodedata.push(nodeobj)
      }
    }
    calcChartdata()
    setItems(orgnodedata)

  }, [slidervalue]);

  
  if(!roleview) {
    return null;
  }

  

  function handleChangeSlider(event) {
    setSliderValue(event.target.value);
  }

  function setNodeTemplate(obj, diagram) {

    let content = new StackPanel();
    content.width = 140;
    content.height = 70;
    content.style.strokeColor = backgroundcolor;
    content.style.strokeWidth = 1;
    content.id = obj.id + "_outerstack";
    let text = new TextElement();
    text.content = obj.data.Role;
    text.style.color = backgroundcolor;
    text.style.bold = false;
    text.style.strokeColor = "none";
    text.margin = { left: 0, right: 0, top:10, bottom: 0 };
    text.style.fill = "none";
    text.id = obj.id + "_text1";
    let desigText = new TextElement();
    desigText.margin = { left: 0, right: 0, top: 5, bottom: 0 };
    desigText.content = obj.data.Name;
    desigText.style.color = backgroundcolor;
    text.style.bold = true;
    desigText.style.strokeColor = "none";
    desigText.style.fill = "none";
    desigText.style.textWrapping = "Wrap";
    desigText.id = obj.id + "_desig";
    content.children = [text, desigText];
    return content;
}


  return (
    <Fragment>

    <section>
      <nav className="level pb-0 mb-0 pt-5">
                <div className="level-left">
                    <div className="level-item">
                        <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "20px", lineHeight: "22px", paddingTop: "35px"}}>
                            <div className="pr-1">
                            ORGANIZATIONAL CHART  ({headcount})<br/><br/>
                            </div>
                        </div>
                    </div>
                </div>
    </nav>
    </section>

      <div className='m-0 p-0' style={{border: "1px solid lightgrey"}}>      
        <div className="is-centered m-0 p-0" style={{overflow:'hidden'}}>
          <OrgChartRender chartdata = {items}/>
        </div>
      </div>
      
      <div class="columns is-centered m-0 pb-0" style={{backgroundColor:backgroundcolor}}>
      <p className="pb-0 mb-0 pt-1" style={{fontSize:"16px", fontWeight:'600', color:textcolor}}>{moment(sliderdate).format("MMMM DD, YYYY")}</p>
        </div>

      <div className="level mt-0 pt-0" style={{backgroundColor: backgroundcolor, userSelect: "none", height:"25px",width:'100%', backgroundColor: backgroundcolor }}>

        <p className="pl-3 pr-3 pt-0 mt-0" style={{fontSize: "14px", fontWeight: "600", color: "white"}}>{minYear}</p> 
        <input className="slider is-shadowless is-white is-fullwidth is-small is-circle m-0 p-0" style={{width:"100%"}} onChange={(e)=>handleChangeSlider(e)} min="0" max="3650" value={slidervalue} step="1" type="range" /> 
        <p className="pl-3 pr-3" style={{fontSize: "14px", fontWeight: "600", color: "white"}}>{maxYear}</p>
      </div>


    </Fragment>
  );
}

export { OrgChart };
