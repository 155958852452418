import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, PolarSeries, Category, SplineSeries, LineSeries, Legend, Tooltip } from '@syncfusion/ej2-react-charts';
import {filter} from "lodash"
import Matrix from './esg/Matrix';
import StakeHolderModal from './esg/StakeHolderModal';
import CategoryModal from './esg/CategoryModal';
import MatrixModal from './esg/MatrixModal';
import ESGChart from './esg/ESGChart';
import {matrixService} from "./../services/StakeholdersMatrixService"
import {companymatrixService} from "./../services/StakeholdersCompanyMatrixService"
import {categoriesService} from "./../services/StakeholdersCategoriesService"
import {stakeholdersService} from "./../services/StakeholdersService"
import Spinner from "./common/Spinner";
import { setMenu } from "../redux/toolkit/features/menu";

function Esg() {
  const dispatch = useDispatch();
  dispatch(setMenu('esg'));
  const [stakeholders, setStakeholders] = useState(null);
  const [categories, setCategories] = useState(null);
  const [matrices, setMatrices] = useState(null);
  const [companymatrix, setCompanyMatrix] = useState(null);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isStakeholderModalOpen, setIsStakeholderModalOpen] = useState(false);
  const [isMatrixModalOpen, setIsMatrixModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [categorymodalkey, setCategoryModalKey] = useState(false);
  const [stakeholdermodalkey, setStakeholderModalKey] = useState(false);
  const palette = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"];
  const [isDataLoading, setIsDataLoading] = useState(false);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)

  async function fetchESG() {
    const matrices = await matrixService.getAll();
    setMatrices(matrices);  
    const categories = await categoriesService.getAll();
    setCategories(categories);  
    const stakeholders = await stakeholdersService.getAll();
    setStakeholders(stakeholders);  
    const companymatrix = await companymatrixService.getAll();
    setCompanyMatrix(companymatrix);  

  }

  useEffect(() => {
    async function fetchData() {
      setIsDataLoading(true);
      await fetchESG();
      setIsDataLoading(false);
    }
    fetchData();
  }, []);

  function toggleCategoryModal(open, id) {
    setCategoryModalKey(id)
    setIsCategoryModalOpen(open);
  }

  function toggleStakeholderModal(open, id) {
    setStakeholderModalKey(id)
    setIsStakeholderModalOpen(open);
  }

  function toggleCompanyModal(open, id) {
    setCategoryModalKey(id)
    setIsCompanyModalOpen(true) 
    setIsMatrixModalOpen(open);
    console.log("At Comp togge", id, open, isCompanyModalOpen)
  }

  function toggleMatrixModal(open, stakeholderId, categoryId) {
    setStakeholderModalKey(stakeholderId)
    setCategoryModalKey(categoryId)
    setIsCompanyModalOpen(false) 
    setIsMatrixModalOpen(open);
  }

  async function onCategoryChange(key, name, description ){
    if (key) {
      await categoriesService.patch(key, {name: name, description: description});
  } else {
      await categoriesService.post({name: name, description: description});
      }
    await fetchESG();
  }

  async function onStakeholderChange(key, name, description ){
    if (key) {
      await stakeholdersService.patch(key, {name: name, description: description});
  } else {
       await stakeholdersService.post({name: name, description: description});
      }
      await fetchESG();
  }

  async function onMatrixChange(key, importance, description, stakeholderId, categoryId){
    if (key) {
      await matrixService.patch(key, {importance: importance, description: description});
    } else {
      await matrixService.post({importance: importance, description: description, stakeholder: stakeholderId, category: categoryId });
    }
    await fetchESG();
}

  async function onCompanyChange(key, importance, description, categoryId ){
    if (key) {
      await companymatrixService.patch(key, {importance: importance, description: description, category: categoryId});
  } else {
      await companymatrixService.post({importance: importance, description: description, category: categoryId});
      }
      await fetchESG();
  }


    async function onStakeholderDelete(key) {
      await stakeholdersService.deleteRow(key);
      setIsStakeholderModalOpen(false)
      await fetchESG();
    }

    async function onCategoryDelete(key) {
      await categoriesService.deleteRow(key);
      setIsCategoryModalOpen(false)
      await fetchESG();
    }

  if (!matrices || !categories || !stakeholders || !companymatrix) {
    return <Spinner/>;
  }

  function PolarChart() {
    let dataarray=[]
    for (const srow of stakeholders) {
      let subarray=[]
    for (const row of categories) {
      let rowobj={};
      let namevalue=srow.name
      rowobj['Category'] = row.name
      let rowvalue=filter(matrices,{category: row.pk, stakeholder: srow.pk})
      rowobj['y'] = (rowvalue[0]?(rowvalue[0].importance)/10:0);
      subarray.push(rowobj)
      }
      dataarray.push(subarray)
    }
    const tooltip = {
      enable: true, header: '${series.name}',
      format: '<b>${point.x} : ${point.y}</b>'};
    const primaryxAxis = { title: '', valueType: 'Category' };
    const primaryyAxis = { minimum: 0, maximum: 10, interval: 1, labelFormat: '{value}' };
    return <ChartComponent className="is-centered" id='charts' primaryXAxis={primaryxAxis} palettes={palette} primaryYAxis={primaryyAxis} width={'1400'} height={'900'} tooltip={tooltip}>
      <Inject services={[PolarSeries, LineSeries, SplineSeries, Category, Legend, Tooltip]}/>
      <SeriesCollectionDirective>
        {renderPolarChart(dataarray)}
      </SeriesCollectionDirective>
      </ChartComponent> 
  }

  function renderPolarChart(dataarray) {
    const cells = [];
    for (let i = 0; i < stakeholders.length; i++) {
        var rowname = stakeholders[i].name
        cells.push(<SeriesDirective dataSource={dataarray[i]} xName='Category' yName='y' name={rowname} width={4} drawType='Line' type='Polar'
        marker={{
          visible: true, height: 7, width: 7, shape: 'Circle'}}
        />);
    }
    return cells;
}


function renderstakeholderpage() {
  return (
<Fragment>
      <main className='has-navbar-fixed-top' style={{marginTop: '3.5em'}}>   
        <section style={{paddingLeft: '12%', paddingRight: '12%', marginTop: '2em'}}>
        <div style={{fontWeight: '600',fontFamily: 'Gotham Extra',fontSize: '32px',lineHeight: '26px',paddingBottom: '28px'}}>
            <div className='pr-1' style={{userSelect:'none'}}>
              STAKEHOLDER ENGAGEMENT
            </div>
            </div>

            <nav className="level pb-0 mb-0 pt-5">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "22px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        STAKEHOLDER HEAT MAP<br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>
            <div className='navbar-end'>
              <div className='buttons mt-3'>
                <a className='button is-small' style={{color: backgroundcolor, borderColor: backgroundcolor, borderRadius:"5px"}} onClick={() => toggleStakeholderModal(true)}>
                  Add Stakeholder
                </a>
                <a className='button is-small' style={{color: backgroundcolor, borderColor: backgroundcolor, borderRadius:"5px"}} onClick={() => toggleCategoryModal(true)}>
                  Add Category
                  </a>
              </div>
            </div>
          </nav>
          {categories && stakeholders && matrices && (
            <Matrix
              categories={categories}
              stakeholders={stakeholders}
              matrices={matrices}
              companymatrix={companymatrix}
              toggleModal={toggleMatrixModal}
              toggleCategoryModal={toggleCategoryModal}
              toggleStakeholderModal={toggleStakeholderModal}
              toggleCompanyModal={toggleCompanyModal}
            />
          )}

          <nav className="level pb-0 mb-0 pt-5">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "22px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        STAKEHOLDER MATRIX<br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>
          </nav>

          <ESGChart
            categories={categories}
            stakeholders={stakeholders}
            matrices={matrices}
            companymatrix={companymatrix}
          />

          <nav className="level pb-0 mb-0 pt-5">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "22px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        STAKEHOLDER POLAR CHART<br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>
          </nav>
      
          <div className="columns is-flex is-centered is-fullwidth pt-3">

              {categories.length!==0 &&
                <Fragment>
                  {PolarChart()}
                </Fragment>
              }

              {categories.length===0 &&
                <Fragment>
                  <p className="mt-5" style={{fontSize:"14px"}}><b>No Data</b></p>
                </Fragment>
              }
          </div>
        </section>
      </main>
      
      {isStakeholderModalOpen && <StakeHolderModal
        isStakeholderModalOpen={isStakeholderModalOpen}
        toggleModal={toggleStakeholderModal}
        stakeholdermodalkey={stakeholdermodalkey}
        stakeholders={stakeholders}
        onStakeholderChange={onStakeholderChange}
        onStakeholderDelete={onStakeholderDelete}
       />}

      {isCategoryModalOpen && <CategoryModal
        isCategoryModalOpen={isCategoryModalOpen}
        toggleModal={toggleCategoryModal}
        categorymodalkey={categorymodalkey}
        categories={categories}
        onCategoryChange={onCategoryChange}
        onCategoryDelete={onCategoryDelete}
         />}

      {isMatrixModalOpen && <MatrixModal 
        toggleModal={toggleMatrixModal}
        isMatrixModalOpen={isMatrixModalOpen}
        isCompanyModalOpen={isCompanyModalOpen}
        matrices={matrices}
        companymatrix={companymatrix}
        categorymodalkey={categorymodalkey}
        stakeholdermodalkey={stakeholdermodalkey}
        onMatrixChange={onMatrixChange}
        onCompanyChange={onCompanyChange}
      />}
      
    </Fragment>

  )
}

  return (
    (isDataLoading?<Spinner/>:renderstakeholderpage())
  );
}

export { Esg };
