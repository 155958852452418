import React, {Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { openPeopleModal, fetchAllPersons} from "./../../redux/toolkit/features/organization/persons";
import { openDeleteModal, closeDeleteModal } from "./../../redux/toolkit/features/modals/deleteConfirmModal";


function People() {
    const showModalPeople = useSelector((state) => state.persons.isOpen)
    const people = Object.values(useSelector((state) => state.persons.entities));
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);  
    const deleteModalStatus = useSelector((state) => state.deleteConfirmModal.isOpen);
    const [textfilter, setfilterquery] = useState('');
    var [filteredPeople] = [];
    const dispatch = useDispatch(); 
  
    async function getPeople() {
      dispatch(fetchAllPersons());
    }

    useEffect(() => {
      if (!showModalPeople) {
        getPeople()
      }
    },[showModalPeople]);
  
    useEffect(() => {
      if (!deleteModalStatus && recordtype==='people') {
        getPeople()
        dispatch(closeDeleteModal())
      }
    },[deleteModalStatus]);



    filteredPeople = people.filter((person) => {
        if (person.firstname.toLowerCase().includes(textfilter.toLowerCase()) || person.secondname.toLowerCase().includes(textfilter.toLowerCase())){
          return true;
        }
        return filteredPeople;
      })   

    function handleChangeFilter(event) {
        setfilterquery(event.target.value);
    }

    function peoplelist(peoplerow){
      return (
        <tr>
        <td className=" pl-0" style={{cursor:'pointer'}} onClick={() => dispatch(openPeopleModal(peoplerow.pk))}>{peoplerow.firstname} {peoplerow.secondname}</td>
        <td><a onClick={async () => {
                        dispatch(openDeleteModal({message: (peoplerow.firstname + " " + peoplerow.secondname),recordpk: peoplerow.pk, recordtype: 'people'}));
                        }}><i className="far fa-trash-alt mt-1" style={{color:"gray"}}/></a></td>
        </tr>
      )
    }

    if (!people) {
      return null
    }

    return (
        <Fragment>
          <section className="mb-6">
              <nav className="level pb-0 mb-0 pt-5">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "20px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        PEOPLE DIRECTORY  ({filteredPeople.length})<br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="level-right">
                            <div className="level-item pt-4 pr-0">
                                <div className="field has-addons">
                                    <div className="control is-rounded is-small" style={{width: "140px",userSelect: "none"}}>
                                        <span> <input  onChange={handleChangeFilter} className="input is-rounded is-small"  type="search" placeholder="Filter" style={{ color: "rgb(128,128,128)",userSelect: "none"}}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    
                    <hr className="pt-0 mt-0 pb-0 mb-0" style={{borderTop: "2px solid lightgrey"}} />
              <table className="table is-fullwidth">
                <tr>
                  <th className="pl-0">Name</th>
                  <th style={{width:"8px"}} ><a onClick={() => dispatch(openPeopleModal(null))}><button style={{color: backgroundcolor}} className="fas fa-plus-circle"></button></a></th>
                </tr>

              {filteredPeople.length!==0 && (filteredPeople.sort((a, b) => (a.firstname.localeCompare(b.firstname)))).map(row => (
                peoplelist(row)
              ))}

              {filteredPeople.length===0  && 
                <Fragment> 
                    <p className="pt-4" style={{fontWeight:'500'}}>No People Found</p>
                </Fragment>
              }
              </table>
                


            </section>
        </Fragment>
    )
}

export {People};