import React, {Fragment, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import {filter} from "lodash"
import { closeSuccessBaseModalRoadmaps, openSuccessBaseModal } from "./../../../redux/toolkit/features/success_state";
import { createRoadmaplinks } from "./../../../redux/toolkit/features/success/successroadmaplinks";

function SuccessModalLinksRoadmaps() {
    const showSuccessLinksRoadmapsModal = useSelector((state) => state.success_state.isSuccessBaseModalRoadmapsOpen);
    const successId = useSelector((state) => state.success_state.successBaseRoadmapSuccessId);
    const sections = Object.values(useSelector((state) =>  state.sections.entities));
    const rows = Object.values(useSelector((state) =>  state.roadmaprows.entities));
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const [isSectionOpened, setIsSectionOpened] = useState(false);
    const [isRowOpened, setIsRowOpened] = useState(false);
    const [rowlist, setRowlist] = useState([]);
    const [rowvalue, setRowvalue] = useState(null)
    const [sectionvalue, setSectionvalue] = useState(null)
    const dispatch = useDispatch();


    let modalClass = "modal";
    if (showSuccessLinksRoadmapsModal) {
        modalClass += " is-active";
    }

    function rowToggle(event) {
        setIsRowOpened(true)
        setRowvalue(event.target.value)
    }
      
    function sectionToggle(event) {
        if(isRowOpened) {
            setIsRowOpened(false)
        }
        setIsSectionOpened(true)
        setSectionvalue(event.target.value)
        setRowlist(filter(rows, function(row) {return row.section===Number(event.target.value)&& (row.type!=="Text")}));
    }
      

    function selectRoadmapLink() {
        return (
            <Fragment>
            <div className="field is-horizontal is-align-items-left">
            <label className="label field-label">Section</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <div className="select is-flex">
                            <select className="select is-fullwidth" onChange={(event) => sectionToggle(event)}>
                            {!isSectionOpened &&
                            <option selected>{'<Select Section>'}</option>}
                                {sections &&
                            sections.map((p) => (
                            <option key={p.pk} value={p.pk}>
                                {p.name} 
                                </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            {isSectionOpened && 
            <div className="field is-horizontal is-align-items-left">
            <label className="label field-label">Row</label>
                <div className="field-body is-flex">
                    <div className="control is-flex-grow-1">
                        <div className="select is-flex">
                            <select className="select is-fullwidth" onChange={(event) => rowToggle(event)}>
                            {!isRowOpened && <option>{'<Select Category>'}</option>}
                                {rowlist &&
                            rowlist.map((p) => (
                            <option key={p.pk} value={p.pk}>
                                {p.name} 
                                </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>}     
        </Fragment>
        )
    }

 
    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
            <div className="modal-background" key="modalBackground">
                {" "}
            </div>
            <div className="modal-card" key="modalCard">
                <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                    <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>ROADMAP LINK</p>
                    <button className="delete" aria-label="close" 
                    onClick={() => {
                        dispatch(closeSuccessBaseModalRoadmaps());
                        dispatch(openSuccessBaseModal({linkModaltype:"ROADMAP", successBaseId: successId}));
                    }}
                    >
                        {" "}
                    </button>
                </header>
                <section className="modal-card-body" key="modalBody">
                {selectRoadmapLink()}
                </section>
                <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>

                    {isRowOpened && 
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                dispatch(createRoadmaplinks({success: successId,row_id: rowvalue, section_id: sectionvalue}))
                                .unwrap()
                                .then(() => {
                                    dispatch(closeSuccessBaseModalRoadmaps());
                                    dispatch(openSuccessBaseModal({linkModaltype:"ROADMAP", successBaseId: successId}));
                                })
                            }}
                        >
                            Create Link
                        </button>
                    }

                    <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} 
                        onClick={() => {
                            dispatch(closeSuccessBaseModalRoadmaps());
                            dispatch(openSuccessBaseModal({linkModaltype:"ROADMAP", successBaseId: successId}));
                            }}
                        >
                        Cancel
                    </button>
                </footer>
            </div>
        </div>
    </Fragment>
    );
}

export default SuccessModalLinksRoadmaps
