import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllCurrentuser = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/users/current`,
    authConfig()
  );
  console.log("CurrentUSer API", response.data)
  return response.data;
};

export const fetchCurrentuser = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/users/current/${id}`,
    authConfig()
  );
  return response.data;
};

export const createCurrentuser = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/users/current`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateCurrentuser = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/users/current/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteCurrentuser = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/users/current/${id}`,
    authConfig()
  );
  return response.data;
};

const currentuserAPI = {
  fetchAllCurrentuser,
  fetchCurrentuser,
  createCurrentuser,
  updateCurrentuser,
  deleteCurrentuser,
};
export default currentuserAPI;
