import React, {Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../common/css/dot.css";
import { RolesDetail } from './RolesDetail';
import { uniqBy } from "lodash"
import { fetchAllRoles } from "./../../redux/toolkit/features/roles";
import { fetchAllPersonsview } from "../../redux/toolkit/features/organization/personview";
import { openRolesModal } from "../../redux/toolkit/features/organization_state";


function Roles() {
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const positions = Object.values(useSelector((state) => state.roles.entities));
    const personview = Object.values(useSelector((state) => state.personsview.entities));
    const showRolesModal = useSelector((state) => state.roles.isOpen)
    const [isCurrent, setIsCurrent] = useState(false)
    const [textfilter, setTextfilter] = useState('')
    var [filteredpositions] = [];
    const dispatch = useDispatch(); 

    async function getRoles() {
        dispatch(fetchAllRoles());
        dispatch(fetchAllPersonsview());
      }

      console.log("PersonView", personview)

    useEffect(() => {
        if (!showRolesModal) {
        getRoles()
        }
    },[showRolesModal]);

    filteredpositions = positions.filter((position => {
        let positiontitle=(position.title!==null?position.title:"Random 83839292")
        if (positiontitle.toLowerCase().includes(textfilter.toLowerCase())){
            if (!isCurrent || (isCurrent && (position.startdate===null))) {
                return true;
            }
            
            return filteredpositions;
        }
    }));




    if (!positions) {
      return null
    }

    return (
        <Fragment>
          <section>
              <nav className="level pb-0 mb-0 pt-5">
                        <div className="level-left">
                            <div className="level-item">
                                <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "20px", lineHeight: "22px", paddingTop: "35px"}}>
                                    <div className="pr-1">
                                        ROLE DIRECTORY  ({positions.length})<br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="level-right">
                            <div className="level-item pt-4 pr-4">
                                <label className="checkbox subtitle" style={{fontWeight: "400", fontFamily: "Gotham Extra", fontSize: "16px"}}>
                                    <span className="mr-2">
                                        <small>Show Current</small>
                                    </span>
                                    <input
                                        type="checkbox" checked={isCurrent}  onClick={() =>{isCurrent?setIsCurrent(false):setIsCurrent(true)}}
                                    />
                                </label>
                            </div>

                            <div className="level-item pt-4 pr-0">
                                <div className="field has-addons">
                                    <div className="control is-rounded is-small" style={{width: "140px",userSelect: "none"}}>
                                        <span> <input className="input is-rounded is-small"  type="search" placeholder="Filter" onChange={(e) => setTextfilter(e.target.value)} style={{ color: "rgb(128,128,128)",userSelect: "none"}}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    
                    <hr className="pt-0 mt-0 pb-0 mb-0" style={{borderTop: "2px solid lightgrey"}} />
              <table className="table is-fullwidth">
                <tr>
                  <th className="pl-0"> Role </th>
                  <th style={{textAlign:'center'}}>Incumbent</th>
                  <th style={{textAlign:'center'}}>Start Date</th>
                  <th style={{textAlign:'center'}}>End Date</th>
                  <th style={{textAlign:'center'}}>Current Compensation</th>
                  <th style={{textAlign:'center'}}>Competencies</th>
                  <th style={{textAlign:'center'}}>isPipeline?</th>
                  <th width="6px"></th>
                  <th width="6px"></th>
                  <th className="ml-0 mr-0 pl-0 pr-0" style={{width:"6px"}} ><a onClick={() => dispatch(openRolesModal({roleId:null}))}><button className="fas fa-plus-circle ml-0 mr-0 pl-0 pr-0" style={{color: backgroundcolor}}></button></a></th>
                </tr>

              {filteredpositions.length!==0 && filteredpositions.map(row => (
                <Fragment>
                    <RolesDetail 
                        row={row}
                        personview={personview} />
                </Fragment>
              ))}

              {filteredpositions.length === 0 && 
                <Fragment> 

                    <p className="pt-4" style={{fontWeight:'500'}}>No Roles Found</p>

                </Fragment>
              }
              </table>
              


            </section>
        </Fragment>
    )
}

export {Roles};