import React, { Fragment, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CellValue } from "./CellValue";
import {filter} from "lodash"
import { roadmapdatasets } from '../Roadmaps';
import { celldatasets } from './Roadmap';
import { openRowNoteModal } from "../../redux/toolkit/features/roadmaprows";
import { openEditRowsModal } from "../../redux/toolkit/features/roadmaps_state";
import { fetchAllBaselinedelta } from "../../redux/toolkit/features/success/baselinedelta";

function Row(props) {
    const {indent, row, percentages, isbaseline, editmode, cells} = props;
    const isEditFormula = useSelector((state) => state.roadmaps_state.isEditFormula)
    //const cells = (useContext(celldatasets)).cells;
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const [indentonestatus, setIndentonestatus] = useState(true)
    const {minYear, maxYear} = useContext(roadmapdatasets);
    const baselinedelta = Object.values(useSelector((state) => state.baselinedelta.entities));
    const baselinedeltavalue = filter(baselinedelta,{row_id:row.pk})

    var alertstatus = 'None'
    if (filter(baselinedeltavalue,{alertstatus:'Moderate'}).length !==0) {
        alertstatus='Moderate'
    }
    if (filter(baselinedeltavalue,{alertstatus:'Severe'}).length !==0) {
        alertstatus='Severe'
    }
    var tdstyle;
    const tdrowstylevalue = row.borderstyle;
    const dispatch = useDispatch();
    //const [rowformat, setRowFormat] = useState(0);
    var rowformat = row.level;
    var leftmargin = "ml-2";
    var fontWeight = 600;
    var fontStyle = 'normal';
    var stylestring = {};
    var borderbottomsetting;
    var bordertopsetting;

    // useEffect(() => {
    //     console.log("RL1",row.level)
    //     if (row.level !==  rowformat) {
    //         console.log("RL",row.level)
    //         setRowFormat(row.level)
    //     }
    // }, [row]);

    useEffect(() => {
        dispatch(fetchAllBaselinedelta());
    }, []);

    useEffect(() => {
    }, [editmode]);


    function renderCellValue(year) {

        let data = filter(cells, {year: year, row_id: row.pk});
        let numericcell = (data.length !== 0 ? data[0].numeric_value : null)
        let textcell = (data.length !== 0 ? data[0].text_value : null)
        return (
            <CellValue 
                key={`${row.pk}_${year}`} 
                dataarray={data}
                sectionPk={props.sectionPk} 
                rowPk={row.pk} 
                numericcell={numericcell}
                textcell={textcell}
                isbaseline={isbaseline}
                year={year} 
                type={row.type} 
                mediumalert={row.baselinealertmoderate}
                severealert={row.baselinealertsevere}
                decimalplaces={row.decimalplaces}
                formula={row.formula} 
                percentages={percentages}
                editmode={editmode}
                fontweight={fontWeight}
                fontstyle={fontStyle}
                stylestring={stylestring}
                tdrowstylevalue={tdrowstylevalue}
                borderbottomsetting={borderbottomsetting}
                bordertopsetting={borderbottomsetting}
                baselinedelta={baselinedelta}
            />
        )
    }

    function renderRowCellValues() {
        const cell = [];
        for (let year = minYear; year <= maxYear; year++) {
            cell.push(renderCellValue(year));
        }
        return cell;
    }


    function buildRows() {
 
   
        switch(rowformat) {
            case 0:
                leftmargin = 'ml-2';
                fontWeight = 500;
                fontStyle = 'normal';
                break;
            case 1:
                leftmargin = 'ml-5';
                fontWeight = 400;
                fontStyle = 'normal';
                break;
            case 2: 
                leftmargin = 'ml-6';
                fontWeight = 400;
                fontStyle = 'italic';
                break;
        }

        if (editmode) {
            stylestring={textAlign:"center", borderBottom:'none', fontWeight, fontStyle, border:"none" }
        } else {
            stylestring={textAlign:"center", borderBottom:'none', fontWeight, fontStyle, border:"none", userSelect:'none'}
        }

        switch(tdrowstylevalue) {

            case ('None' || null):
                borderbottomsetting="solid 1px #dbdbdb";
                bordertopsetting="solid 1px #dbdbdb";
                break;
            case 'Top':
                borderbottomsetting = "solid 1px #dbdbdb";
                bordertopsetting = "solid 1px rgb(120,120,120)";
                break;
            case 'Bottom': 
                borderbottomsetting = "solid 1px rgb(120,120,120)";
                bordertopsetting = "solid 1px #dbdbdb";
                break;
            case 'TopBottom': 
                borderbottomsetting = "solid 1px rgb(120,120,120)";
                bordertopsetting = "solid 2px rgb(150,150,150)";
            break;
        }

        return (
            <Fragment>
                <td  style={{minWidth:"400px", borderTop: bordertopsetting, borderBottom: borderbottomsetting}} key={"table_"+row.pk}>
                    {/* <input className={leftmargin} style={{border:"none", fontStyle: fontStyle, backgroundColor: "transparent", userSelect:"none", pointerEvents: "none", fontWeight:fontWeight, overFlow: "visible"}} value={row.name} readOnly/><i style={{color:'Red', fontSize:'smaller'}} className="fas fa-bell ml-0 mr-0"/> */}
                    <div className={leftmargin}>
                        <div className="level" style={{height:"100%"}}>
                            <div className="level-left" style={{height: '100%'}}>
                                <div className="level-item has-text-left mr-1">
                                    <p style={{height: '100%', fontSize: "14px", fontStyle: fontStyle, fontFamily: "Segoe UI", whiteSpace:'nowrap', fontWeight:fontWeight}} onClick={() => dispatch(openEditRowsModal({editrowpk:row.pk, editsectionpk:row.section, isAddNewRow: false }))}>{row.name}</p>
                                </div>

                                {alertstatus==='Severe' && 
                                    <div className="level-item m-1">
                                        <i className="fas fa-bell" style={{color:'#eb4034', fontSize:'12px'}} title={'A roadmap value is outside the limit of ' + baselinedeltavalue[0].baselinealertsevere + '% of baseline'}/>                             
                                    </div>
                                }

                                {alertstatus==='Moderate' && 
                                    <div className="level-item m-1">
                                        <i className="fas fa-bell"style={{color:'#fcba03', fontSize:'12px'}} title={'A roadmap value is outside the limit of ' + baselinedeltavalue[0].baselinealertmoderate + '% of baseline'}/>                             
                                    </div>
                                }



                                {row.showcomments &&
                                <div className="level-item" onClick={() => dispatch(openRowNoteModal({note:row.description, rowname: row.name, rowpk: row.pk, section: row.section}) )}>
                                    <i className="fas fa-info-circle " style={{color: backgroundcolor, fontSize:'12px'}}/>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    {renderBaseline()}{renderGrowth()}
                </td>
                {isEditFormula && <td style={{color:backgroundcolor,textAlign:"center",fontSize:"13px", fontWeight:600, verticalAlign: "middle", borderTop: bordertopsetting, borderBottom: borderbottomsetting}}>{row.pk}</td>}
                {row.formula===null && renderRowCellValues()}
                {(row.formula!==null && !isEditFormula) && renderRowCellValues()}
                {isEditFormula && row.formula !==null && <td colSpan={6}><input style={{width:"100%",textAlign:"center"}}value={row.formula} /></td>}
            </Fragment>
            )
        }



    function renderBaseline() {
        if ((filter(baselinedelta, {row_id: row.pk})).length !== 0 && isbaseline) {
            return (
                <Fragment>
                <div className={leftmargin}>
                    <div className="ml-2 mt-2">
                        <input style={{border:"none", backgroundColor: "transparent", lineHeight: "16px", userSelect:"none", pointerEvents: "none", fontSize: "0.75rem", fontWeight:"500", width: "100%"}} value='Baseline' readOnly/>
                        <input style={{border:"none", backgroundColor: "transparent", lineHeight: "16px", userSelect:"none", pointerEvents: "none", fontSize: "0.75rem", width: "100%"}} value='Delta ∆' readOnly/>
                        <input className="mb-1" style={{border:"none", backgroundColor: "transparent", lineHeight: "16px", userSelect:"none", pointerEvents: "none", fontSize: "0.75rem", width: "100%"}} value='Delta %' readOnly/>
                    </div>
                </div>
            </Fragment>
            )
        } 
    }


    function renderGrowth() {
        if (props.percentages) {
            return (
                <Fragment>
                    <div className={leftmargin}>
                        <div className="ml-2 mt-2">
                            <input style={{border:"none", backgroundColor: "transparent", lineHeight: "12px", userSelect:"none", pointerEvents: "none", fontSize: "0.75rem", fontWeight:"500", width: "100%"}} value='Annual Growth' readOnly/>
                            <input style={{border:"none", backgroundColor: "transparent", lineHeight: "12px", userSelect:"none", pointerEvents: "none", fontSize: "0.75rem", fontWeight:"500", width: "100%"}} value='CAGR' readOnly/>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return;

        }
    }

    return (
        <Fragment>

            {buildRows()}
        </Fragment>
    );
}

export { Row };
