import React, { Fragment, useRef, useEffect, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LayoutAnimation, HierarchicalTree, Diagram, DiagramConstraints, Rect, NodeModel, ConnectorModel, DataBinding, PortConstraints, PortVisibility, DiagramComponent, SnapConstraints, DiagramTools } from "@syncfusion/ej2-react-diagrams";
import { fetchAllSprints, updateSprints, selectSprintById } from "../../redux/toolkit/features/sprints/sprints";
import {ReactComponent as DecisionIcon} from './icons/decision.svg';
import {ReactComponent as LaneIcon} from './icons/lane.svg';
import {ReactComponent as EndIcon} from './icons/end.svg';
import {ReactComponent as TaskIcon} from './icons/task.svg';
import {ReactComponent as ConnectorIcon} from './icons/connector.svg';

function SprintLane(props) {
    const {sprintPK, editSprintlane, setEditSprintlane} = props;
    const sprintbyid = useSelector((state)=>selectSprintById(state, sprintPK));  
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor);
    const textcolor = useSelector((state) => state.colors.textcolor);
    const dispatch = useDispatch();

    var node = [];
    var diagramElement;
    var diagram = new Diagram();

    if (sprintbyid.sprintlanedata!==null){
        diagramElement= document.getElementById("SprintLane"+sprintPK);
        if (diagramElement) {
            diagram= diagramElement.ej2_instances[0];
            diagram.loadDiagram(sprintbyid.sprintlanedata);
        }
    }

    useEffect(() => {
        async function sprintlanedata() {
            dispatch(fetchAllSprints())
        }
    sprintlanedata()
      },[editSprintlane]);

      let ports= [{
        // Define a port with an ID to connect a connector to it
        id: 'port1',
        // Sets the position for the port
        offset: {
            x: 0.5,
            y: 0.5
        },
        visibility: PortVisibility.Visible
    }]


     // Initialize port collection
     let port = [{
        id: 'port1',
        offset: {
            x: 0,
            y: 0.5
        },
      },
      {
      id: 'port2',
      offset: {
          x: 1,
          y: 0.5
        },
      },
      {
        id: 'port3',
        offset: {
            x: 0.5,
            y: 0
          },
      },
      {
        id: 'port4',
        offset: {
            x: 0.5,
            y: 1
          },
      } 
    ]

    if (sprintbyid.sprintlanedata===null) {
       node = [{
        shape: {
            // Set the node type as swimlane
            type: 'SwimLane',
            orientation: 'Horizontal',
            header: {
                annotation: { content: 'Title', style: { color: 'white', fontSize: 13} },
                height: 40, 
                style: { fill: backgroundcolor },
            },
            lanes: [
                {
                    id: 'stackCanvas1',
                    height: 100,
                    header: {
                        annotation: { content: 'Lane Title' },
                        width: 50,
                        style: { fontSize: 11 },
                        style: { fill: 'white' }

                      },
                      children: [
                        {
                          id: 'node1',
                          annotations: [
                            {
                              content: 'Process',
                              style: { fontSize: 11 }
                            }
                          ],
                          margin: { left: 60, top: 30 },
                          height: 40, width: 100, ports: port
                        },
                        {
                          id: 'node2',
                          shape: { type: 'Flow', shape: 'Decision' },
                          annotations: [
                            {
                              content: 'Decision',
                              style: { fontSize: 11 }
                            }
                          ],
                          margin: { left: 200, top: 20 },
                          height: 60, width: 120, 
                          ports: port
                        },
                        {
                            id: 'node3',
                            shape: { type: 'Flow', shape: 'Terminator' },
                            annotations: [
                              {
                                content: 'Terminator',
                                style: { fontSize: 11 }
                              }
                            ],
                            margin: { left: 360, top: 30 },
                            height: 40, width: 100, ports: port
                          },
                    ],
                },
            ],
        },
        offsetX: 420, offsetY: 120,
        height: 242,
        width: 842
    }];
    }

    function SaveSwimlane() {
        let diagramElementSave = document.getElementById("SprintLane"+sprintPK);
        let diagramSave = new Diagram();
        diagramSave = diagramElementSave.ej2_instances[0];
        let saveData = diagramSave.saveDiagram();
        dispatch(updateSprints({id:sprintPK, sprintlanedata: saveData}))
        .unwrap()
        dispatch(fetchAllSprints());
        setEditSprintlane(false);
    }

    var diagramInstance;

    function drawbox(type) {
        let drawingshape = {
            type: 'Flow',
            shape: type,
          };
          let node = {
            shape: drawingshape,
            id: new Date().getTime(),
            ports: port
          };
          diagramInstance.drawingObject = node;
          //To draw an object once, activate draw once
          diagramInstance.tool = DiagramTools.DrawOnce;
          diagramInstance.dataBind();
    }

    function addLane() {
        let lane = [{ id: new Date().getTime(), height: 100 }];
        diagramInstance.addLanes(diagramInstance.nodes[0], lane, 1);
        diagramInstance.dataBind();
    }

    function addConnector() {
        let connectors = {
            id: new Date().getTime(),
            type: 'Orthogonal',
            segments: [{
                type: "orthogonal"
            }]
        }
        diagramInstance.drawingObject = connectors;
        diagramInstance.tool = DiagramTools.DrawOnce;
        diagramInstance.dataBind();
    }

   return (
    <Fragment>
    {editSprintlane && <Fragment>
        <div className="mt-4">
        <button title="Draw Process" className="button " onClick={() => drawbox('Process')}><TaskIcon/></button>
        <button title="Draw Decision" className="button " onClick={() => drawbox('Decision')}><DecisionIcon/></button>
        <button title="Draw Terminator" className="button " onClick={() => drawbox('Terminator')}><EndIcon/></button>
        <button title="Draw Connector" className="button " onClick={() => addConnector()}><ConnectorIcon/></button>
        <button title="Add Lane" className="button " onClick={() => addLane()}><LaneIcon/></button>
        </div>
    </Fragment>
    }
    <div className="p-0 m-0" style={{overflow:'hidden', margin:'5px'}}>

     <DiagramComponent
       id={"SprintLane"+sprintPK}
       ref={(diagram) => (diagramInstance = diagram)}
       scrollSettings={{canAutoScroll: false, scrollLimit: 'Diagram'}}
       //snapSettings={{ constraints: SnapConstraints.None }}
       //snapSettings={{ constraints: SnapConstraints.ShowLines }}

       
       width={'100%'}
       height={'242px'}
       nodes={node}
       //tool={DiagramTools.None}
       //constraints={
   // DiagramConstraints.Default & ~DiagramConstraints.PageEditable}
       //tool={DiagramTools.DrawOnce}
    //    created = {
    //     () => {
    //         //JSON to create a Connector
    //         let drawingshape = {
    //             type: 'Basic',
    //             shape: 'Rectangle',
    //           };
    //           let node = {
    //             shape: drawingshape,
    //           };
    //           diagramInstance.drawingObject = node;
    //           //To draw an object once, activate draw once
    //           diagramInstance.tool = DiagramTools.DrawOnce;
    //           diagramInstance.dataBind();
    //         }}
            // diagramInstance.drawingObject = connectors;
            // //To draw an object once, activate draw once
            // diagramInstance.tool = DiagramTools.DrawOnce;
            // diagramInstance.dataBind();
        
     />
   {editSprintlane && <Fragment>
        <div className="mt-4">
        <button className="button is-success is-small mr-2" onClick={()=>SaveSwimlane()}>Save</button>
        <button className="button is-small mr-2" onClick={() => setEditSprintlane(false)}>Cancel</button>
        <button className="button is-danger is-small" style={{backgroundColor:backgroundcolor, color: textcolor}} onClick={() => dispatch(fetchAllSprints())}>Reset</button>
        </div>
    </Fragment>}
   </div>

   </Fragment>
   );
}

export { SprintLane };
