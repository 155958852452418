import React, {Fragment, useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {_} from 'lodash'
import {filter} from 'lodash'
import { Container } from "./rows/container";
import { selectSectionById, fetchAllSections, createSections, updateSections } from "../../../redux/toolkit/features/roadmapsection";
import { openRoadmapSectionsModal, closeRoadmapSectionsDetailModal, openEditRowsModal } from "../../../redux/toolkit/features/roadmaps_state";
import { fetchAllRows } from "../../../redux/toolkit/features/roadmaprows";

function SectionsModalAddSection() {
    const tabs = ['Section', "Rows"]
    const showAddSectionsModal = useSelector((state) => state.roadmaps_state.isDetailModalOpen)
    const isAddNewSection = useSelector((state) => state.roadmaps_state.isAddNewSection)
    const sectionpk = useSelector((state) => state.roadmaps_state.sectionpk)
    const sections = Object.values(useSelector((state) => state.sections.entities)).sort((a, b) => a.order - b.order);
    const rows = Object.values(useSelector((state) => state.roadmaprows.entities)).sort((a, b) => a.order - b.order);
    const sectionbyid = useSelector((state)=>selectSectionById(state, sectionpk))  
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const showSectionsModal = useSelector((state) => state.roadmaps_state.isOpen)
    const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);  
    const deleteModalStatus = useSelector((state) => state.deleteConfirmModal.isOpen);
    const [activetab, setActivetab] = useState(tabs[0]);
    const [name, setName] = useState('');
    const [narrative, setNarrative] = useState('');
    const [category, setCategory] = useState('');
    const [newcategory, setNewCategory] = useState('');
    const [charts, setCharts] = useState(false);
    const [gantt, setGantt] = useState(false);
    const [sectionrows, setSectionRows] = useState([]);
    var categorylist = [...new Set(sections.flatMap(({category}) => category))].sort(); 
    const dispatch = useDispatch();

    let modalClass = 'modal';
    if (showAddSectionsModal) {
        modalClass += ' is-active'
    }
    let modalTitle=null;
    if (sectionpk) { modalTitle='EDIT ROADMAP SECTION'} else {modalTitle='NEW ROADMAP SECTION'}
   
    useEffect(() => {
        dispatch(fetchAllSections());
        setName(sectionbyid?sectionbyid.name:"")
        setNarrative(sectionbyid?sectionbyid.narrative:"")
        setCategory(sectionbyid?sectionbyid.category:"")
        setCharts(sectionbyid?sectionbyid.showcharts:false)
        setGantt(sectionbyid?sectionbyid.showgantt:false)
        setSectionRows(filter(rows, {section:sectionpk }));
    }, [sectionpk]);

    useEffect(() => {
        if(deleteModalStatus && recordtype==='row') {
            dispatch(fetchAllRows());
            setSectionRows(filter(rows, {section:sectionpk }));
        }
    }, [deleteModalStatus]);

    function renderSection() {
        return (
            <Fragment>
                
                <p className="mb-1" style={{fontSize:"smaller"}}>Roadmap Title</p>
                <div className='mb-4 field is-horizontal is-align-items-center'>
                    <div className='field-body is-flex'>
                        <div className='control is-flex-grow-1'>
                        {name !== undefined &&
                            <input
                                className='input'
                                type='text'
                                placeholder='Roadmap Title'
                                value={name}
                                onChange={async (event) => {setName(event.target.value)}}
                            />}
                        </div>
                    </div>
                </div>
    
                <p className="mb-1" style={{fontSize:"smaller"}}>Roadmap Narrative</p>
                <div className='mb-4 field is-horizontal is-align-items-center'>
                    <div className='field-body is-flex'>
                        <div className='control is-flex-grow-1'>
                        {narrative !== undefined &&    
                            <textarea
                                className='textarea'
                                rows='4'
                                placeholder='Roadmap Narrative'
                                value={narrative}
                                onChange={async (event) => {setNarrative(event.target.value)}}
                            />}
                        </div>
                    </div>
                </div>
    
                <p className="mb-1" style={{fontSize:"smaller"}}>Category</p>
                <div className='field is-horizontal is-align-items-center'>
                    <div className='field-body is-flex'>
                        <div className='control is-flex-grow-1'>
                        {category !== undefined &&
                            <Fragment>
                                <div className='select is-flex'>
                                    <select
                                        className='select is-fullwidth'
                                        value={category}
                                        onChange={async (event) => {setCategory(event.target.value)}}
                                    >
                                     {category === '' && <option selected>{'<Select Category>'}</option>}
                                    {categorylist &&
                                        categorylist.map((p) => (
                                        <option key={p} value={p}>
                                            {p}
                                        </option>
                                        ))}
                                        <option key='new'>
                                        &lt;New&gt;</option>
                                    </select>
                                </div>
                            </Fragment>}
                        </div>
                    </div>
                </div>

                {category==='<New>' &&
                <div className='mb-4 field is-horizontal is-align-items-center'>
                    <div className='field-body is-flex'>
                        <div className='control is-flex-grow-1'>
                        {name !== undefined &&
                            <input
                                className='input'
                                type='text'
                                placeholder='Enter New Category'
                                value={newcategory}
                                onChange={async (event) => {setNewCategory(event.target.value)}}
                            />}
                        </div>
                    </div>
                </div>
                }



                <div className="mt-4 mb-3">
                    <label className="checkbox">
                    <input className="mr-3" type="checkbox" checked={charts} onChange={(e) => setCharts(e.target.checked)}/>
                    <span>Include Graphs</span>
                    </label>
                </div>

                <div>
                    <label className="checkbox">
                    <input className="mr-3" type="checkbox" checked={gantt} onChange={(e) => setGantt(e.target.checked)}/>
                    Include Gantt Chart
                    </label>
                </div>
    
            </Fragment>
        )
    }

    function formtabs() {
        return (
            <Fragment>
            <div className='tabs mt-0 pt-0 pb-0 mb-0 pl-1' style={{backgroundColor:"rgb(238, 238, 238)"}}>
                <div className="pl-1">
                <ul>
                    {tabs.map(tab => (
                        <li className ={activetab === tab ? 'is-active' : ''}
                            key={'tab_'+tab}
                            onClick={() => setActivetab(tab)}
                        >
                        <a>{tab}</a>
                        </li>
                    ))}
                </ul>
            </div>
            </div>
            <hr style={{borderTop:"1px solid lightgrey"}}className="mt-0 pt-0"/>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className={modalClass} key='categoryModal' style={{userSelect: 'none'}}>
                <div className='modal-background' key='modalBackground'>
                    {' '}
                </div>
                <div className='modal-card' key='modalCard' style={{width: "550px"}}>
                    
                    <header className='modal-card-head' key='modalHeader' style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className='modal-card-title ml-1' style={{fontSize:"17px", color: textcolor}}>{modalTitle}</p>
                        <button className='delete' aria-label='close' onClick={() => dispatch(closeRoadmapSectionsDetailModal())}>
                            {' '}
                        </button>
                    </header>
                    
                    <section className="modal-card-body pt-0 pl-0 pr-0" key='modalBody' style={{minHeight: '350px', maxHeight: "600px"}}>
                    {sectionpk !== null &&
                        formtabs()
                    }
                    <div className="ml-5 mr-5 mt-0">
                    {activetab==="Section" && sectionpk === null && <div className="mt-4">{renderSection()}</div>}
                    {activetab==="Section" && sectionpk !== null && <div className="mt-0">{renderSection()}</div>}
                    {activetab==="Rows" && <Fragment>
                        {sectionrows.length !== 0 &&
                            <Container rows={sectionrows} sectionpk={sectionpk}/>
                        }
                        {sectionrows.length === 0 &&
                            <p className = "pt-2" style={{fontSize:"14.5px", fontWeight: "400"}}>No Records Returned</p>
                        }
                    </Fragment>}
                    </div>
                    </section>
                    
                    <footer className='modal-card-foot is-flex' key='modalFooter' style={{height:"60px"}}>
                                {activetab === "Section" && sectionpk === null && category !== "" && category !== '<New>' &&
                                              
                                    <button
                                        style={{borderRadius:"4px"}}
                                        className='button is-success is-small'
                                        key='save'
                                        onClick={async () => {
                                                dispatch(createSections({name:name, narrative: narrative, category:category, roadmap: 1, showcharts: charts, showgantt:gantt  }))
                                                .unwrap()
                                                    dispatch(fetchAllSections());
                                                    dispatch(closeRoadmapSectionsDetailModal())
                                                    {isAddNewSection && dispatch(openRoadmapSectionsModal()) }  
                                        }}
                                    >
                                
                                        Save Section
                                    </button>
                                }

                                        {activetab === "Section" && sectionpk === null && category === '<New>' && newcategory !=="" &&
                                              
                                              <button
                                                  style={{borderRadius:"4px"}}
                                                  className='button is-success is-small'
                                                  key='save'
                                                  onClick={async () => {
                                                          await dispatch(createSections({name:name, narrative: narrative, category:newcategory, roadmap: 1, showcharts: charts, showgantt:gantt  }))
                                                          .unwrap()
                                                              dispatch(fetchAllSections());
                                                              dispatch(closeRoadmapSectionsDetailModal());
                                                              {isAddNewSection && dispatch(openRoadmapSectionsModal())}  
                                                  }}
                                              >
                                          
                                                  Save Section
                                              </button>
                                          }


                                    {activetab === "Rows" && sectionpk !== null &&          
                                              
                                              <button
                                                  style={{borderRadius:"4px", backgroundColor: backgroundcolor, color: textcolor}}
                                                  className='button is-small'
                                                  key='save'
                                                  onClick={async () => {
                                                        dispatch(closeRoadmapSectionsDetailModal())
                                                        dispatch(openEditRowsModal({editrowpk: null,isAddNewRow: true, editsectionpk:sectionpk}))
                                                  }}
                                              >
                                          
                                                  Add Row
                                              </button>
                                          }

                                {activetab === "Section" && sectionpk !== null &&          
                                              
                                    <button
                                        style={{borderRadius:"4px"}}
                                        className='button is-success is-small'
                                        key='save'
                                        onClick={async () => {
                                                dispatch(updateSections({id:sectionpk, name:name, narrative: narrative, category:category, roadmap: 1, showcharts: charts, showgantt:gantt}))
                                                .unwrap()   
                                                    dispatch(fetchAllSections());
                                                    dispatch(closeRoadmapSectionsDetailModal());
                                        }}
                                    >
                                
                                        Save Section
                                    </button>
                                }




                                    <button className='button is-small' key='cancel' style={{borderRadius:"4px"}} onClick={() => dispatch(closeRoadmapSectionsDetailModal())}>
                                        Cancel
                                    </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export {SectionsModalAddSection};
