import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class CategoriesService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/categories`;
    }

    async getCategories() {
        const categories = await this.getAll();
        return categories;
    }
}

export const categoriesService = new CategoriesService();
