import React, { Fragment, useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import "../common/css/dot.css";
import { peopledatasets } from '../Organization';
import {filter} from "lodash"
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, LineSeries,ColumnSeries, StackingColumnSeries } from '@syncfusion/ej2-react-charts';
import { openProductivityModal } from "./../../redux/toolkit/features/organization_state"
import { fetchAllProductivity } from "./../../redux/toolkit/features/organization/productivity";
import { fetchAllMetrics } from "./../../redux/toolkit/features/organization/metrics";

function OrgMetrics(props) {
  const {minYear, maxYear, cellvalues} = props;
  const {rows, growth, cagr} = useContext(peopledatasets);
  const productivity = Object.values(useSelector((state) => state.productivity.entities));
  const metrics = Object.values(useSelector((state) => state.metrics.entities));
  const [radioStatus, setRadioStatus] = useState(true);
  const [key, setKey] = useState(0);
  const [chartname, setChartName] = useState(0);
  const dispatch = useDispatch(); 
  const unitProductivity = 170;
  var chartarray=[];
  const palette = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"]

  productivity.map((prod, index)=> {
    let indexvalue=index*2
    let rowarray=filter(rows,{pk:prod.row})
    let rowname=rowarray[0]?rowarray[0].name:""
    chartarray.push({index: indexvalue, chartrow:rowname, title: rowname + ' (per Head)', pk: prod.row, type: 'headcount'})
    chartarray.push({index: indexvalue+1, chartrow:rowname, title: rowname + ' (per Unit Compensation)', pk: prod.row, type: "compensation"})
})

  useEffect(() => {
    dispatch(fetchAllProductivity());
    dispatch(fetchAllMetrics());
  },[]);


   function renderGrowthChart() {
    let data = [];

    for (const row of productivity) {
      var growthdata=[]
      var chartrow = []
      for (let year = minYear; year <= maxYear; year++) {
        if (radioStatus) {
        chartrow = filter(growth, {row_id: row.row, year: year})
        } else {
        chartrow = filter(cagr, {row_id: row.row, year: year})
        }
        var growthobj={}
        growthobj['year']=year;
        growthobj['y']=(chartrow[0]?chartrow[0].growth:null);
        growthdata.push(growthobj)
      }
      data.push(growthdata)
    }

    let peopledata = [];
    var growthdata=[]
    var chartrow = []
    for (let year = minYear; year <= maxYear; year++) {
      chartrow = filter(metrics,{year: year})
      var growthobj={}
      growthobj['year']=year;
      if (radioStatus===true) {growthobj['y']=chartrow[0]?chartrow[0].compensationgrowth:null;}
      if (radioStatus!== true) {growthobj['y']=chartrow[0]?chartrow[0].compensationgrowthcagr:null;}
      growthdata.push(growthobj)
    }
      peopledata.push(growthdata)

      let headcountdata = [];
      var growthdata=[]
      var chartrow = []
      for (let year = minYear; year <= maxYear; year++) {
        chartrow = filter(metrics,{year: year})
        var growthobj={}
        growthobj['year']=year;
        if (radioStatus===true) {growthobj['y']=chartrow[0]?chartrow[0].headcountgrowth:null;}
        if (radioStatus!== true) {growthobj['y']=chartrow[0]?chartrow[0].headcountgrowthcagr:null;}
        growthdata.push(growthobj)
      }
        headcountdata.push(growthdata)

        let avgcompensationdata = [];
        var growthdata=[]
        var chartrow = []
        for (let year = minYear; year <= maxYear; year++) {
          chartrow = filter(metrics,{year: year})
          var growthobj={}
          growthobj['year']=year;
          if (radioStatus===true) {growthobj['y']=chartrow[0]?chartrow[0].avgcompensationgrowth:null;}
          if (radioStatus!== true) {growthobj['y']=chartrow[0]?chartrow[0].avgcompensationgrowthcagr:null;}
          growthdata.push(growthobj)
        }
          avgcompensationdata.push(growthdata)

  const primaryxAxis = { valueType: 'Category', fontWeight: 'bold'};
  const primaryyAxis = { title: 'Annual Change' , labelFormat: '{value}%', titleStyle: {fontWeight: 'lighter'}};
  const legendSettings = { visible: true, position: 'Bottom' };
  const chartArea = {background: "white"}
  const marker = { visible: true, width: 12, opacity: 1, height: 10, shape: 'Circle'};
  const margin = { right: 60}

  return (
    <Fragment>
  <ChartComponent id='charts' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} legendSettings={legendSettings} chartArea={chartArea} palettes={palette} margin={margin}>
    <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, LineSeries, Category]}/>
    <SeriesCollectionDirective>
      {rendergrowthchartdata(data, productivity)}
      <SeriesDirective dataSource={headcountdata[0]} xName='year' yName='y' name="Headcount" type='Line' width={4} marker={marker}/>
      <SeriesDirective dataSource={peopledata[0]} xName='year' yName='y' name="Total Compensation" type='Line'  width={4} marker={marker}/>
      <SeriesDirective dataSource={avgcompensationdata[0]} xName='year' yName='y' name="Avg. Compensation" type='Line'  width={4} marker={marker}/>
    </SeriesCollectionDirective>
  </ChartComponent>
  </Fragment>
  )
  }
  

  function rendergrowthchartdata(data, chartindex) {
    const cells = [];
    for (let i = 0; i < chartindex.length; i++) {
        var rowname = (filter(rows, {pk: chartindex[i].row}))[0].name
        cells.push(<SeriesDirective dataSource={data[i]} xName='year' yName='y' name={rowname} type='Column'/>);
    }
    return cells;
}


function renderunitchartdata(data, chartindex) {
  const cells = [];
  let rownamelist = filter(rows, {pk: unitProductivity})
  var rowname = rownamelist[0]?rownamelist[0].name:null
  cells.push(<SeriesDirective dataSource={data} xName='year' yName='y' name={rowname} type='Column'/>);
  return cells;
}

  function renderUnitChart() {
    let data = [];
    var dataarray=[]
    for (let year = minYear; year <= maxYear; year++) {

      // Load Metrics Reference Data
      let metricsrow = filter(metrics,{year: year})
      let metricsvalue = [];
      var yaxistitle="";
      var rowPK;
      if (chartarray.length!== 0) {
       let typearray=filter(chartarray,{index:parseInt(chartname)})
        yaxistitle=typearray[0].chartrow
        rowPK=typearray[0].pk
        if (typearray[0].type==="compensation") {

          metricsvalue = metricsrow[0]?metricsrow[0].totalcompensation:null
        } else {
          yaxistitle=typearray[0].chartrow
          metricsvalue = metricsrow[0]?metricsrow[0].totalheadcount:null
        }
      }

      //Load Row Cell Data
      let chartrow = filter(cellvalues, {row_id:rowPK, year: year})
      let chartvalue = chartrow[0]?chartrow[0].numeric_value:null
      let headcountratio=metricsvalue!==0 ||metricsvalue!==null?chartvalue/metricsvalue:null
      var unitobj={}
      unitobj['year']=year;
      unitobj['y']=headcountratio
      dataarray.push(unitobj)
    }
    data.push(dataarray)
    const primaryxAxis = { interval: 1, labelIntersectAction: 'Rotate45', valueType: 'Category' };
    const primaryyAxis = { title: yaxistitle, titleStyle: {fontWeight: 'lighter'}};
    const chartArea = {background: "white"}
    const margin = { right: 60}
    return <ChartComponent id='charts1' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} chartArea={chartArea} palettes={palette} margin={margin}>
      <Inject services={[ColumnSeries, LineSeries, Legend, Tooltip, DataLabel, Category]}/>
      <SeriesCollectionDirective>
      <SeriesDirective dataSource={data[0]} xName='year' yName='y' name="Headcount" type='Column'></SeriesDirective>
      </SeriesCollectionDirective>
    </ChartComponent>;
  }

  
  function buildSelectList() {   
    var optionlist=[];    
    {chartarray.map((row, index) => {
      optionlist.push(<option key={row.index} name={row.index} value={index}>{row.title}</option>)
    })}
    return (
      optionlist
    )
  }
 

  return (
    <Fragment>
  <section style={{backgroundColor: "rgb(242,242,242)", fontFamily: "Gotham Extra"}}>
        <div className="level m-0 p-3" style={{backgroundColor: "rgb(242,242,242)"}}>
            <div className="level-left">
                <p className="pl-4" style={{fontSize: "18px", fontWeight: "600"}}>COMPARITIVE PRODUCTIVITY</p> 
            </div>
            <div className="level-right">
            <div className="level-right pr-5">
                  <div className="control pl-4 pt-3" style={{fontSize:"12px"}}>
                    <label className="radio">
                      <input className="mr-1" type="radio" name="annual" onClick={() => setRadioStatus(true)} checked={radioStatus===true}/>
                      AGR
                      </label>
                      <label className="radio">
                      <input className="mr-1" type="radio" name="annual" onClick={() => setRadioStatus(false)} checked={radioStatus===false}/>
                      CAGR
                    </label>
                  </div>
                </div>
            <button className="button is-small mr-4 is-right" style={{backgroundColor: "rgb(238,238,238)", border:"2px solid rgb(202,202,202)",borderRadius:"4px"}}onClick={() => dispatch(openProductivityModal())}><i className="fas fa-link"></i></button>
            </div>
        </div>
          {renderGrowthChart()}
        </section>

        <section className="mt-6 mb-6" style={{backgroundColor: "rgb(242,242,242)", fontFamily: "Gotham Extra"}}>
        <div className="level m-0 p-3" style={{backgroundColor: "rgb(242,242,242)"}}>
            <div className="level-left">
                <p className="pl-4" style={{fontSize: "18px", fontWeight: "600"}}>UNIT BASED PRODUCTIVITY</p> 
            </div>
            <div className="level-right pr-4">
            <div className="pl-4 pt-2">
                    <label style={{fontSize:"14px", fontWeight: 600}}>Displaying: </label>
                    <select 
                        className="mb-4 ml-2 pl-2 pr-3"  
                        value={chartname}
                        onChange={(e)=> {
                          setKey(e.currentTarget)
                          setChartName(e.currentTarget.value)
                        }}
                    >
                          {buildSelectList()}
                    </select>
                </div>                 
            </div>
        </div>
  {       renderUnitChart()}
        </section>

        </Fragment>
    )
}

export { OrgMetrics };