import React, {Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { closeEditRowsModal, openRoadmapSectionsModal } from "../../../redux/toolkit/features/roadmaps_state";
import { selectRowById } from "../../../redux/toolkit/features/roadmaprows";
import { createRows, updateRows } from "../../../redux/toolkit/features/roadmaprows";
import { fetchAllRows } from "../../../redux/toolkit/features/roadmaprows";


function SectionsModalEditRows() {
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor);
    const textcolor = useSelector((state) => state.colors.textcolor);
    const rowpk = useSelector((state) => state.roadmaps_state.editrowpk);
    const sectionpk = useSelector((state) => state.roadmaps_state.editsectionpk);
    const isEditRowsModalOpen = useSelector((state) => state.roadmaps_state.isEditRowsModalOpen);
    const isAddNewRow = useSelector((state) => state.roadmaps_state.isAddNewRow);
    const rowbyid = useSelector((state)=>selectRowById(state, rowpk));
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [level, setLevel] = useState(null);
    const [shownotes, setShowNotes] = useState("");
    const [borderstyle, setBorderStyle] = useState("");
    const [subrow, setSubrow] = useState(null);
    const [decimalplaces, setDecimalplaces] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        //dispatch(fetchAllSections());
        setName(rowbyid?rowbyid.name:"")
        setType(rowbyid?rowbyid.type:"Number")
        setShowNotes(rowbyid?rowbyid.showcomments:"false")
        setDecimalplaces(rowbyid?rowbyid.decimalplaces:"0")
        setBorderStyle(rowbyid?rowbyid.borderstyle:"None")
        setLevel(rowbyid?rowbyid.level:0)
    }, [rowpk]);


    let modalClass = 'modal';
    if (isEditRowsModalOpen) {
        modalClass += ' is-active'
    }

    function modalform() {

        return (
            <Fragment>
            <p className="mb-1" style={{fontSize: "smaller"}}>Row Name</p>
            <div className="field is-horizontal is-align-items-center">
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    {name !== undefined &&
                    <input
                        className="input"
                        type="text"
                        placeholder="Row Name"
                        value={name}
                        onChange={async (event) => {setName(event.target.value)}}
                    />}
                </div>
            </div>
        </div>

        <p className="mb-1" style={{fontSize: "smaller"}}>Data Type</p>
        <div className="field is-horizontal is-align-items-center">
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    <div className="select is-flex">
                        <select className="select is-fullwidth" value={type} onChange={async (event) => {setType(event.target.value)}}>
                            <option>Text</option>   
                            <option>Number</option>
                            <option>Percent</option>                            
                            <option>Header</option>   
                            {/* <option>Formula</option> */}

      
                        </select>
                    </div>
                </div>
            </div>
        </div>

        {(type === 'Number' ||  type === 'Percent') &&
        <Fragment>
        <p className="mb-1" style={{fontSize: "smaller"}}>Decimal Places</p>
        <div className="field is-horizontal is-align-items-center">
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    <div className="select is-flex">
                        <select className="select is-fullwidth" value={decimalplaces} onChange={async (event) => {setDecimalplaces(event.target.value)}}>
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option> 
                            <option value={5}>5</option>                
                        </select>
                    </div>
                </div>
            </div>
        </div>
        </Fragment>
        }

        <p className="mb-1" style={{fontSize: "smaller"}}>Level</p>
        <div className="field is-horizontal is-align-items-center">
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    <div className="select is-flex">
                        <select className="select is-fullwidth" value={level} onChange={async (event) => {setLevel(event.target.value)}}>
                            <option value={0}>First Level</option>
                            <option value={1}>Second Level</option>
                            <option value={2}>Third Level</option>             
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <p className="mb-1" style={{fontSize: "smaller"}}>Border Style</p>
        <div className="field is-horizontal is-align-items-center">
            <div className="field-body is-flex">
                <div className="control is-flex-grow-1">
                    <div className="select is-flex">
                        <select className="select is-fullwidth" defaultValue="None" value={borderstyle} onChange={async (event) => {setBorderStyle(event.target.value)}}>
                            <option value={'None'}>None</option>   
                            <option value={'Top'}>Top</option>
                            <option value={'Bottom'}>Bottom</option>                            
                            <option value={'TopBottom'}>Top & Bottom</option>   
     
                        </select>
                    </div>
                </div>
            </div>
        </div>



        <div className="mt-2">
            <label className="checkbox">
            <input className="mr-3" type="checkbox" checked={shownotes} onChange= {(e) => setShowNotes(e.target.checked) }/>
            Display Note
            </label>
        </div>
      

        </Fragment>
        )
    }

    return (
        <Fragment>
        <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
            <div className="modal-background" key="modalBackground">
                {" "}
            </div>
            <div className="modal-card" key="modalCard">
            <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
            <p className="modal-card-title" style={{fontSize:"16px", color: textcolor}}>ROW DETAILS</p>
                    <button className="delete" aria-label="close" onClick={() => dispatch(closeEditRowsModal())}>
                        {" "}
                    </button>
                </header>
                <section className="modal-card-body" key="modalBody" style={{minHeight: "330px"}}>
                  {modalform()}
                </section>
                <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>

                    {rowpk === null &&
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(createRows({name: name,type:type, decimalplaces: decimalplaces, level: level, section: sectionpk, showcomments:shownotes, borderstyle: borderstyle  }))
                                .unwrap()
                                    dispatch(fetchAllRows());
                                    dispatch(closeEditRowsModal());
                                    {isAddNewRow && dispatch(openRoadmapSectionsModal());}
                            }}
                        >
                            Save Changes
                        </button>
                    }

                    {rowpk !== null &&
                        <button
                            style={{borderRadius:"4px"}}
                            className="button is-success is-small"
                            key="save"
                            onClick={async () => {
                                await dispatch(updateRows({id: rowpk, name: name,type:type, decimalplaces: decimalplaces, level: level,section: sectionpk, showcomments:shownotes, borderstyle: borderstyle }))
                                .unwrap()
                                    dispatch(fetchAllRows());
                                    dispatch(closeEditRowsModal());
                                    {isAddNewRow && dispatch(openRoadmapSectionsModal());}
                            }}
                        >
                            Save Changes
                        </button>
                    }

                    <button className="button is-small" key="cancel" onClick={() => dispatch(closeEditRowsModal())}>
                        Cancel
                    </button>
                </footer>
            </div>
        </div>

    </Fragment>
    );
}

export {SectionsModalEditRows}
