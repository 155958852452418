import update from 'immutability-helper'
import React, { useCallback, useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import {Card} from './card.js'
import {roadmapsrowService} from "../../../../services/RoadmapsRowService.js";
import { orderRows } from '../../../../redux/toolkit/features/roadmaprows.js';


export const Container = (props) => {
        const [rows, setRows] = useState([]);
        const dispatch = useDispatch();

    useEffect(() => {
        setRows(props.rows);
    }, [props]);


    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setRows((prevRows) => {
                const newRows = update(prevRows, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevRows[dragIndex]],
                    ],
                });
                const newRowsPks = newRows.map(row => row.pk);
                dispatch(orderRows({'sorted_pks':newRowsPks, section:props.sectionpk}));
                //roadmapsrowService.sortRows(newRowsPks)//.then(() => getFactsOnMainPage());
                return newRows;
            }
        )
    }, [])

    const renderRow = useCallback(
        (row, index) => {
            return (
                <Card
                    key={row.pk}
                    index={index}
                    id={row.pk}
                    text={row.name}
                    moveCard={moveCard}
                    rowPk={row.pk}
                />
            )
        },
        [],
    )

    return (
        <>
            <div className="m-0 p-0">{rows.map((card, i) => renderRow(card, i))}</div>
        </>
    )
}