import React, { Fragment, useEffect, useState } from 'react';
import './common/css/main.css';
import './common/css/masterstyles.css';
import { useDispatch, useSelector } from 'react-redux';
import { menuActions } from '../redux/actions/menu';
import { SuccessLinks } from './success/SuccessLinks';
import { SuccessGrid } from './success/SuccessGrid';
import SuccessModal from './success/modals/SuccessModal';
import SuccessBaseModal from './success/modals/SuccessBaseModal';
import SuccessBaselineModal from './success/modals/SuccessBaselineModal';
import SuccessYearModal from './success/modals/SuccessYearModal';
import ConfirmDeleteBox from './common/ConfirmDeleteBox';
import Spinner from "./common/Spinner";
//import { setMenu } from "../redux/toolkit/features/menu";
import { fetchAllSuccess, deleteSuccess} from '../redux/toolkit/features/success/success';
import { fetchAllSuccessyear } from '../redux/toolkit/features/success/successyear';
import { openSuccessModal, openSuccessYearModal, showGridPage, openSuccessRecordModal} from '../redux/toolkit/features/success_state';
import { deleteInnovationlinks, fetchAllInnovationlinks } from '../redux/toolkit/features/success/successinnovationlinks';
import { deleteRoadmaplinks, fetchAllRoadmaplinks } from '../redux/toolkit/features/success/successroadmaplinks';
import { deleteSprintlinks, fetchAllSprintlinks } from '../redux/toolkit/features/success/successsprintlinks';
import SuccessModalDetail from './success/modals/SuccessModalDetail'
import SuccessModalLinksThemes from './success/modals/SuccessModalLinksThemes';
import SuccessModalLinksRoadmaps from './success/modals/SuccessModalLinksRoadmaps';
import SuccessModalLinksSprints from './success/modals/SuccessModalLinksSprints';
import { closeDeleteModal }  from "./../redux/toolkit/features/modals/deleteConfirmModal";


function Success() {
  const success = Object.values(useSelector((state) => state.success.entities)).sort((a, b) => a.order - b.order);
  const successstatus = Object.values(useSelector((state) => state.success.status));
  const successyear = Object.values(useSelector((state) => state.successyear.entities));
  const linkedrows = Object.values(useSelector((state) => state.roadmaplinks.entities));
  const linkedthemes = Object.values(useSelector((state) => state.innovationlinks.entities));
  const linkedsprints = Object.values(useSelector((state) => state.sprintlinks.entities));
  const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);
  const recordkey = useSelector((state) => state.deleteConfirmModal.recordpk);   

  const isSuccessModalOpen = useSelector((state) => state.success_state.isSuccessModalOpen);
  const isSuccessBaseModalOpen = useSelector((state) => state.success_state.isSuccessBaseModalOpen);
  const showSuccessLinksInnovationModal = useSelector((state) => state.success_state.isSuccessBaseModalInnovationOpen);
  const showSuccessLinksSprintsModal = useSelector((state) => state.success_state.isSuccessBaseModalSprintsOpen);
  const showSuccessLinksRoadmapsModal = useSelector((state) => state.success_state.isSuccessBaseModalRoadmapsOpen);
  const isSuccessYearModalOpen = useSelector((state) => state.success_state.isSuccessYearModalOpen);
  const showBaselineModal = useSelector((state) => state.success_state.isBaselineModalOpen);
  const isSuccessRecordModalOpen = useSelector((state) => state.success_state.isSuccessRecordModalOpen);
  const showModalDelete = useSelector((state) => state.deleteConfirmModal.isOpen);

  const isGridVisible = useSelector((state) => state.success_state.isGrid)
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const [isDataLoading, setIsDataLoading] = useState(false);
  const dispatch = useDispatch();
  //dispatch(setMenu('success'));


  async function fetchSuccess() {
    dispatch(fetchAllSuccess())
    dispatch(fetchAllSuccessyear())
    dispatch(fetchAllInnovationlinks())
    dispatch(fetchAllRoadmaplinks())
    dispatch(fetchAllSprintlinks())
  }

  useEffect(() => {
      async function fetchData() {
        setIsDataLoading(true);
        await fetchSuccess();
        setIsDataLoading(false);
      }
      fetchData();
  }, []);

  useEffect(() => {
    dispatch(menuActions.setActiveMenu('success'));
  }, );

  async function onConfirmDelete(result) {
    if (result) {

        if (recordtype === 'roadmap') {
            await dispatch(deleteRoadmaplinks(recordkey))
                .unwrap()
                    dispatch(fetchAllSuccess());
                    dispatch(fetchAllRoadmaplinks());
                    dispatch(closeDeleteModal());
        }

        if (recordtype === 'sprint') {
            await dispatch(deleteSprintlinks(recordkey))
                .unwrap()
                  dispatch(fetchAllSuccess());
                  dispatch(fetchAllSprintlinks());
                  dispatch(closeDeleteModal());
        }

        if (recordtype === 'theme') {
            await dispatch(deleteInnovationlinks(recordkey))
                .unwrap()
                  dispatch(fetchAllSuccess());
                  dispatch(fetchAllInnovationlinks());
                  dispatch(closeDeleteModal());
        }

        if (recordtype === 'success') {
          await dispatch(deleteSuccess(recordkey))
              .unwrap()
                dispatch(fetchAllSuccess());
                dispatch(closeDeleteModal());
      }
    }
  }

  useEffect(() => {
    renderSuccessGrid();
  }, [isGridVisible]);

  if (!success || !linkedrows || !linkedthemes || !linkedsprints || !successyear) {
    return <Spinner/>;
  }

  function renderSuccessGrid() {
    if (isGridVisible) {
      return (

        <SuccessGrid 
          success = {success}
          linkedrows = {linkedrows}
        />
      ) 
    }
  }

  function renderSuccessSection() {
    if (isGridVisible) {
      return null;
    }

    return (

    <SuccessLinks 
      linkedrows = {linkedrows}
      linkedthemes = {linkedthemes}
      linkedsprints = {linkedsprints}
      />
    )
  }

  function rendersuccesspage() {
    return (
    <Fragment>
      <main className='has-navbar-fixed-top'>
        <section style={{paddingLeft :'12%',paddingRight: '12%',marginTop: '5.5em', userSelect: 'none'}}>
          <div style={{fontWeight: '600',fontFamily: 'Gotham Extra',fontSize: '32px',lineHeight: '26px',paddingBottom: '28px'}}>
            <div className="pr-0 mr-0">
              SUCCESS VISION {!isGridVisible && <a style={{fontFamily: "Sans-serif", fontSize: "15px", fontWeight: "500", color: backgroundcolor}} className="is-pulled-right" onClick={() => dispatch(showGridPage())}>{"<< Back"}</a>}
              {isGridVisible  && <button className="button is-small is-pulled-right mr-0" style={{borderColor:backgroundcolor, color: backgroundcolor, borderRadius:"5px"}} onClick={() => dispatch(openSuccessModal())}>Edit</button>}
            </div>
            {successyear.length !== 0 &&
            <span style={{fontFamily: 'Gotham Extra',fontSize: '17px',fontWeight: '500'}}>BY END OF YEAR <a className= "m-0 p-0" style={{color:"rgb(40,40,40)", fontFamily: 'Gotham Extra',fontSize: '17px',fontWeight: '500'}} onClick={() => dispatch(openSuccessYearModal())}>{successyear[0].year}</a> OUR SUCCESS WILL REFLECT: </span>}
            {successyear.length === 0 &&
            <span style={{fontFamily: 'Gotham Extra',fontSize: '17px',fontWeight: '500'}}>BY END OF YEAR <button className= "button mt-1 pt-0 pb-0" style={{color:"black", fontFamily: 'Gotham Extra',fontSize: '11.5px',fontWeight: '500'}} onClick={() => dispatch(openSuccessYearModal())}>Add Year</button> OUR SUCCESS WILL REFLECT: </span>}


          </div>

          {success.length !== 0 &&
            <Fragment>
              {renderSuccessGrid()}
              {renderSuccessSection()}
            </Fragment>
          }

          {success.length === 0 && successstatus==="idle" &&
            <Fragment>
                  <button className = "button p-3 mt-4" style={{backgroundColor: "transparent", border: "solid 1px darkgrey", fontFamily: "Gotham Extra", fontSize: "16px"}} onClick={() => openSuccessRecordModal({successRecordModalPK:null})} >
                    Add Success Vision
                  </button>
            </Fragment>
          }

          {isSuccessModalOpen && <SuccessModal/>}
          { isSuccessBaseModalOpen && <SuccessBaseModal
            linkedrows={linkedrows}
            linkedsprints={linkedsprints}
            linkedthemes={linkedthemes}
            />}
          {showSuccessLinksInnovationModal && <SuccessModalLinksThemes/>}
          {showSuccessLinksSprintsModal && <SuccessModalLinksSprints/>}
          {showSuccessLinksRoadmapsModal && <SuccessModalLinksRoadmaps/>}
          {isSuccessYearModalOpen && <SuccessYearModal successyear={successyear}/>}
          {showModalDelete && <ConfirmDeleteBox onConfirmDelete={onConfirmDelete}/>}
          {showBaselineModal && <SuccessBaselineModal/>}
          {isSuccessRecordModalOpen && <SuccessModalDetail/>}

        </section>
      </main >
    </Fragment >
    )
  }

  if (!success || !successyear ) {
    return null;
  }

  return (
    (isDataLoading?<Spinner/>:rendersuccesspage())
  );
}

export { Success };
