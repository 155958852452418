import React, { useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {  openRolesModal } from "./../../../src/redux/toolkit/features/organization_state";
import { 
  HierarchicalTree, 
  StackPanel, 
  TextElement,
  SnapConstraints,
  DiagramComponent,
  Inject,
  DataBinding, 
  DiagramTools,
  Node, 
  NodeConstraints,
  ConnectorConstraints, 
  Rect, 
} from "@syncfusion/ej2-react-diagrams";

import { DataManager } from "@syncfusion/ej2-data";

let diagramInstance; 

function OrgChartRender(props) {
  const {chartdata} = props;
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const textcolor = useSelector((state) => state.colors.textcolor)
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState(false);


  useEffect(() => {            
    
  }, []);

  function setNodeTemplate(obj, diagram) {

    let content = new StackPanel();
    content.width = 140;
    content.height = 70;
    content.style.strokeColor = textcolor;
    content.style.fill = backgroundcolor;
    content.style.strokeWidth = 1;
    content.id = obj.id + "_outerstack";
    let text = new TextElement();
    text.content = obj.data.Role;
    text.style.color = textcolor;
    text.style.bold = false;
    text.style.strokeColor = "none";
    text.margin = { left: 0, right: 0, top:10, bottom: 0 };
    text.style.fill = "none";
    text.id = obj.id + "_text1";
    let desigText = new TextElement();
    desigText.margin = { left: 0, right: 0, top: 5, bottom: 0 };
    desigText.content = obj.data.Name;
    desigText.style.color = textcolor;
    text.style.bold = true;
    desigText.style.strokeColor = "none";
    desigText.style.fill = "none";
    desigText.style.textWrapping = "Wrap";
    desigText.id = obj.id + "_desig";
    content.children = [text, desigText];
    return content;
  }

  if(!chartdata) {
    return null;
  }

  return (
    <DiagramComponent 
      id="diagram" 
      ref={(diagram) => (diagramInstance = diagram)}
      width={"100%"} 
      height={"600px"} 
      tool={
        DiagramTools.Default |
        DiagramTools.ZoomPan |
        DiagramTools.SingleSelect
      }
      scrollSettings={{ scrollLimit: 'Diagram', canAutoScroll: false }}
      snapSettings={{constraints: SnapConstraints.None}}
      zoomSettings={{enableMouseWheelZooming: true}}
      layout={{       
        type: "OrganizationalChart",
        horizontalAlignment: "Center",
        verticalAlignment: "Center",
        margin: { top: 20 },
        // This is the code that stops the refresh
        // ----------------------------------------

        // getLayoutInfo: (node, tree) => {
        //   if (!tree.hasSubTree) {
        //     tree.orientation = 'Vertical';
        //     tree.type = 'Right';
        //   }
        // },
      
       // ----------------------------------------
      }} 
        dataSourceSettings={{
        id: "Id",
        parentId: "Team",
        dataManager: new DataManager(chartdata),
      }} 
      getNodeDefaults={(obj, diagram) => {
        obj.height = 70;
        obj.width = 140;

        obj.constraints =
            NodeConstraints.Default & ~NodeConstraints.Select;
        obj.expandIcon = { shape: 'Minus'};
        obj.expandIcon.verticalAlignment = 'Auto';
        obj.collapseIcon = { shape: 'Plus' };
        obj.collapseIcon.verticalAlignment = 'Auto';
        obj.borderColor = backgroundcolor;
        obj.backgroundColor = "White";
        return obj;
      }}

      getConnectorDefaults={(connector, diagram) => {
        connector.style = {
            strokeColor: backgroundcolor,
            strokeWidth: 1,
        };
        connector.targetDecorator.shape = "None";
        connector.type = "Orthogonal";
        connector.constraints =
        ConnectorConstraints.Default & ~ConnectorConstraints.Select;
        return connector;
        }}

        setNodeTemplate={(obj, diagram) => {
          return setNodeTemplate(obj, diagram);
        }}

        created={() => {
          diagramInstance.fitToPage();
        }}

        click={(args) => {
          if (
            args.element instanceof Node &&
            args.actualObject !== undefined
          ) {
            dispatch(openRolesModal({roleId:(args.element.data).Id}))
          }
        }}
        
        expandStateChange={(args) => {
          var visibleNodes = diagramInstance.nodes.filter(function (node) {
            return node.visible;
          });
          var desiredBounds = new Rect();
          for (var i = 0; i < visibleNodes.length; i++) {
            desiredBounds.uniteRect(visibleNodes[i].wrapper.bounds);
          }
          desiredBounds.y = desiredBounds.y - 50;
          diagramInstance.bringToCenter(desiredBounds);
        }}
        >
      
      <Inject services={[DataBinding, HierarchicalTree]}/>
    </DiagramComponent>

  );

}

export default OrgChartRender;
