import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class MatrixService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/matrices`;
    }

    async getMatrix() {
        const matrices = await this.getAll();
        return matrices;
    }
}

export const matrixService = new MatrixService();
