import React, { Fragment, useState, useContext } from 'react';
import { useSelector } from 'react-redux'
import { Row } from './Row';
import {filter} from "lodash";
import { roadmapdatasets } from '../Roadmaps';

function Table(props) {
    const {indent, issuccesspage, sectionrows, sectionPk, isGraph, chart, cells, chartbutton} = props;  
    const isEditFormula = useSelector((state) => state.roadmaps_state.isEditFormula);
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor) 
    const {minYear, maxYear, baselinedelta} = useContext(roadmapdatasets);
    const [isPercentage, setisPercentage] = useState(false);
    const [isBaseline, setisBaseline] = useState(false);
    const [buttonGraphColor, setButtonGraphColor] = useState(backgroundcolor);
    const [buttonPercentColor, setButtonPercentColor] = useState(backgroundcolor);
    const [buttonBaselineColor, setButtonBaselineColor] = useState(backgroundcolor);
    const [buttonEditColor, setButtonEditColor] = useState(backgroundcolor);
    const [buttonGraphWidth, setButtonGraphWidth] = useState('2px');
    const [buttonPercentWidth, setButtonPercentWidth] = useState('2px');
    const [buttonBaselineWidth, setButtonBaselineWidth] = useState('2px');
    const [buttonEditWidth, setButtonEditWidth] = useState('2px');
    const [buttonGraphBorder, setButtonGraphBorder] = useState(backgroundcolor);
    const [buttonPercentBorder, setButtonPercentBorder] = useState(backgroundcolor);
    const [buttonBaselineBorder, setButtonBaselineBorder] = useState(backgroundcolor);
    const [buttonEditBorder, setButtonEditBorder] = useState(backgroundcolor);
    const [isEditmode, setEditMode] = useState(false);
    let baselinerows=filter( baselinedelta,{section_id: sectionPk})
    let baselinerowslength=baselinerows.length

    function renderYearHeaders() {
        const headers = [];
        {isEditFormula && headers.push(<th className={'has-text-centered pt-3'} style={{maxWidth:"15px", color: textcolor}}><i className="fas fa-key"></i></th>)};
        for (let year = minYear; year <= maxYear; year++) {
            if (year <  new Date().getFullYear()) {
            headers.push(<th className="has-text-centered pt-3" style={{color: textcolor}} key={year}>{year} Actual</th>);
            } else {
                headers.push(<th className={'has-text-centered pt-3'} style={{color: textcolor}} key={year}>{year}</th>);
            }
        }

        return headers;
    }

    function showPercentages() {
        if (isPercentage) {
        setisPercentage(false);
        setButtonPercentColor(backgroundcolor);
        setButtonPercentWidth('2px');
        setButtonPercentBorder(backgroundcolor);
        } else {
        setisPercentage(true)
        setButtonPercentColor(backgroundcolor);
        setButtonPercentWidth('2px');
        setButtonPercentBorder(textcolor);
        }
      }

      function showGraphs() {
        if (props.isGraph) {
        props.setisGraph(false);
        setButtonGraphColor(backgroundcolor);
        setButtonGraphWidth('2px');
        setButtonGraphBorder(backgroundcolor);
        } else {
        props.setisGraph(true);
        setButtonGraphColor(backgroundcolor);
        setButtonGraphWidth('2px');
        setButtonGraphBorder(textcolor);
        }
      }

      function showBaseline() {
        if (isBaseline) {
        setisBaseline(false);
        setButtonBaselineColor(backgroundcolor);
        setButtonBaselineWidth('2px');
        setButtonBaselineBorder(backgroundcolor);
        } else {
        setisBaseline(true)
        setButtonBaselineColor(backgroundcolor);
        setButtonBaselineWidth('2px');
        setButtonBaselineBorder(textcolor);
        }
      }

      function showEditMode() {
        setButtonEditWidth('2px');
        if (isEditmode) {
        setEditMode(false);
        setButtonEditColor(backgroundcolor);
        setButtonEditBorder(backgroundcolor);
        } else {
        setEditMode(true)
        setButtonEditColor(backgroundcolor);
        setButtonEditBorder(textcolor);
        }
      }
 

    function renderHeader() {
        if (sectionrows.length > 0) {
            let chartlength=null
            if (chart && chart.results.length !== 0) {
                chartlength=(filter(chart.results, {section: sectionPk})).length
            }

        return (
            <Fragment>
                <tr key={'Tableheader'+sectionPk.toString()} style={{backgroundColor: backgroundcolor, userSelect:"none"}}>
                    <th>
                        <button className='is-small mr-1 pb-1 pl-3 pr-3' title="Show Percentages" style={{ cursor: 'pointer', borderColor: buttonPercentBorder, backgroundColor: buttonPercentColor, color: textcolor, borderRadius: '6px', borderWidth: buttonPercentWidth }} onClick={() => showPercentages()}>%</button>
                        {chartbutton && <button className='is-small' title="Show Graphs" style={{ cursor: 'pointer', borderColor: buttonGraphBorder, backgroundColor: buttonGraphColor, color: textcolor, borderRadius: '6px', borderWidth: buttonGraphWidth }} onClick={() => showGraphs()}><i className='far fa-chart-bar fa-lg'></i></button>}
                        {baselinerowslength !== 0 && <Fragment><button className="is-small" title="Show Baselines" style={{borderColor: buttonBaselineBorder, backgroundColor: buttonBaselineColor, color: textcolor, borderRadius: '6px',borderWidth: buttonBaselineWidth}} onClick={() => showBaseline()}><i className="fas fa-crosshairs fa-lg"></i></button></Fragment>}
                        <Fragment><button className="is-small ml-1" title="Edit" style={{borderColor: buttonEditBorder, backgroundColor: buttonEditColor, color: textcolor, borderRadius: '6px',borderWidth: buttonEditWidth}} onClick={() => showEditMode()}><i className="fas fa-edit fa-lg"></i></button></Fragment>

                    </th>
                    {renderYearHeaders()}
                    </tr>
            </Fragment>
        )
        }
    }   

    function renderRow(row) {

        return (

            <tr key={'Row'+(row.pk).toString()}>
                <Row 
                    row={row} 
                    cells={cells}
                    sectionPk={props.sectionPk} 
                    percentages={isPercentage}
                    isbaseline={isBaseline}
                    baselinedelta={baselinedelta}
                    indent={indent}
                    editmode={isEditmode}
                    />
            </tr>

        )    
    }

    function renderRows() {
        const rows = [];
        if(issuccesspage) {
            for (const row of sectionrows) {
                rows.push(renderRow(row));
            }
            return rows;
        } else if (sectionrows) {
            for (const row of sectionrows) {
                rows.push(renderRow(row));
            }
            return rows;
        } else {
            return '';
        }
        
    }

    return (
        <Fragment>
        <table id="hovertable" key={'table'+sectionPk} className={'table is-fullwidth'}>
            <tbody>
            {renderHeader()}
            {renderRows()}
            </tbody>
        </table>
        </Fragment>
    )
}

export { Table };
