import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";

export const fetchAllStakeholders = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/stakeholders`,
    authConfig()
  );
  return response.data;
};

export const fetchStakeholder = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/stakeholders/${id}`,
    authConfig()
  );
  return response.data;
};

export const createStakeholder = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/stakeholders`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateStakeholder = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/stakeholders/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteStakeholder = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/stakeholders/${id}`,
    authConfig()
  );
  return response.data;
};

const stakeholdersAPI = {
  fetchAllStakeholders,
  fetchStakeholder,
  createStakeholder,
  updateStakeholder,
  deleteStakeholder,
};
export default stakeholdersAPI;
