import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";

class StakeholdersService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/stakeholders`;
    }

    async getMatrix() {
        const stakeholders = await this.getAll();
        return stakeholders;
    }
}

export const stakeholdersService = new StakeholdersService();
