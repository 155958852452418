import {React, useState, Fragment, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Table } from './Table';
import { RoadmapChart } from './Chart';
import { GanttChart} from './GanttChart';
import { filter } from 'lodash'
import { TableGantt} from './TableGantt';
import { openRoadmapSectionsDetailModal } from "../../redux/toolkit/features/roadmaps_state";
import { fetchAllChartRows } from "../../redux/toolkit/features/roadmapchartrows";

function Section(props) {
    const {sectiondata, gantt, rows, cells} = props;
    const sectionPk = sectiondata.pk;
    const sectionrows = filter(rows, {section: sectionPk}) 
    const ganttrows = filter(gantt.results, {section: sectionPk})
    const [isGraph, setisGraph] = useState(false);
    const showSectionsChartEditModal = useSelector((state) => state.roadmaps_state.isGraphEditModalOpen);
    const dispatch = useDispatch();

    useEffect(() => {
    }, [sectionrows]);

    useEffect(() => {
        dispatch(fetchAllChartRows());
    }, [showSectionsChartEditModal]);

    function GanttBody() {

        return (
            <Fragment>
                <TableGantt 
                    sectionpk={sectionPk}
                    sectionrows={sectionrows} 
                    gantt={gantt}
                /> 

                <GanttChart 
                    sectionpk={sectionPk}
                    sectionrows={sectionrows} 
                    gantt={gantt}
                    ganttrows={ganttrows}
                />

            </Fragment>
        )
    }

    function TableBody() {

        return (
            
            <Table 
                key={'table_' + sectionPk} 
                sectionrows={sectionrows} 
                sectionPk={sectionPk} 
                rows={sectiondata.rows}
                setisGraph={setisGraph} 
                isGraph={isGraph}
                chartbutton={sectiondata.showcharts}
                cells={cells}

            />
        )
    }

    if (!sectiondata || !sectionPk) {
        return null;
    }

    return (
        <section className='mb-6' style={{backgroundColor: 'rgb(241,241,241)', borderRadius:"12px"}}>
            <div style={{fontFamily: 'Gotham Extra', fontWeight: 700, fontSize: '22px', lineHeight: '0.9em'}} onDoubleClick={() => dispatch(openRoadmapSectionsDetailModal({sectionpk:sectiondata.pk, isAddNewSection:false}))}>
                <div className='pt-5 pb-5 pl-5 pr-5'>
                    {sectiondata.name}<br/>
                </div>
                <div className='pl-5 pr-5' style={{backgroundColor: 'rgb(241,241,241)'}} onDoubleClick={() => dispatch(openRoadmapSectionsDetailModal({sectionpk:sectiondata.pk}))}>
                <span style={{fontFamily: 'Gotham Extra', fontSize: '15px', lineHeight: '25px'}}>
                    <p>{sectiondata.narrative}</p> 
                </span>
             </div>
             </div>

             <div className='pt-5 pl-5 pr-5 pb-1'>

                {(sectiondata.rows?sectiondata.rows.length:0)!==0 &&
                    <div className="mb-6">{TableBody()}</div>
                }

                {sectiondata.showgantt &&
                    <div className="mb-6">{GanttBody()}</div>
                }
            
            </div>

            {isGraph &&
            <div className='pt-0 pl-5 pr-5 pb-5'>
                <RoadmapChart 
                    key={'chart_' + sectionPk} 
                    sectionPk={sectionPk} 
                    sectionrows={sectionrows} 
                    />
            </div>
            }
        </section>
    )
}

export { Section };
