import React, { useEffect, Fragment, useState, createContext } from "react";
import "./common/css/masterstyles.css";
import PopupModal from "./roadmaps/modals/PopupModal";
import SectionsModal from "./roadmaps/modals/SectionsModal";
import { useDispatch, useSelector} from "react-redux";
import { menuActions } from "../redux/actions/menu";
import { Roadmap } from "./roadmaps/Roadmap";
import { Header } from "./roadmaps/Header";
import { roadmapsService } from "../services/RoadmapsService";
import { baselineValuesService } from "../services/BaselineValuesService";
import { baselineDeltaService } from "../services/BaselineDeltaService";
import { rowindentService } from "../services/RoadmapsIndentService";
import { ganttService } from "../services/RoadmapsGanttService";
import ConfirmDeleteBox from './common/ConfirmDeleteBox';
import Spinner from "./common/Spinner";
import { setMenu } from "../redux/toolkit/features/menu";
import { deleteSections, fetchAllSections } from '../redux/toolkit/features/roadmapsection';
import { deleteRows, fetchAllRows } from '../redux/toolkit/features/roadmaprows';
import { closeDeleteModal }  from "./../redux/toolkit/features/modals/deleteConfirmModal";
import { SectionsModalAddSection }  from "./roadmaps/modals/SectionsModalAddSection";
import { SectionsModalEditRows }  from "./roadmaps/modals/SectionsModalEditRows";
import { SectionsGraphModal }  from "./roadmaps/modals/SectionsGraphModal";
import { SectionsModalEditCharts }  from "./roadmaps/modals/SectionsModalEditCharts";
import { openEditFormula, closeEditFormula } from "../redux/toolkit/features/roadmaps_state";
export const roadmapdatasets = createContext();

function Roadmaps() {
  //const cellvalues = Object.values(useSelector((state) => state.roadmapcells.entities));
  const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);
  const recordkey = useSelector((state) => state.deleteConfirmModal.recordpk);   

  const showSectionsModal = useSelector((state) => state.roadmaps_state.isOpen);
  const isEditRowsModalOpen = useSelector((state) => state.roadmaps_state.isEditRowsModalOpen);
  const showSectionsGraphModal = useSelector((state) => state.roadmaps_state.isGraphModalOpen);
  const showSectionsChartEditModal = useSelector((state) => state.roadmaps_state.isGraphEditModalOpen);
  const showAddSectionsModal = useSelector((state) => state.roadmaps_state.isDetailModalOpen)

  const dispatch = useDispatch();
  dispatch(setMenu('roadmaps'));
  const [roadmap,setRoadmap] = useState(null);
  const isEditFormula = useSelector((state) => state.roadmaps_state.isEditFormula);
  //const [rows,setRows] = useState(null);
  const [gantt, setGantt] = useState(null);
  const [baselinevalues,setBaseline] = useState(null);
  const [baselinedelta,setBaselineDelta] = useState(null);
  const [indent,setIndent] = useState(null);


  const [isDataLoading, setIsDataLoading] = useState(false);

  const test = useSelector((state) => state.roadmaps_state.isDetailModalOpen);

  async function fetchRoadmaps() {
    const roadmap = await roadmapsService.getRoadmap();
    setRoadmap(roadmap);  
    const gantt = await ganttService.getAll();
    setGantt(gantt);  
    const indent = await rowindentService.getRowIndent();
    setIndent(indent);      
    const baseline = await baselineValuesService.getCells();
    setBaseline(baseline);   
    const baselinedelta = await baselineDeltaService.getCells();
    setBaselineDelta(baselinedelta);     
  }

  useEffect(() => {
      async function fetchData() {
        setIsDataLoading(true);
          await fetchRoadmaps();
        setIsDataLoading(false);
      }
      fetchData();
  }, []);


  useEffect(() => {
    document.addEventListener('keydown', (e) => {  
        //e.preventDefault();
        if (e.shiftKey && e.ctrlKey && e.code === 'KeyF') {
          if (!isEditFormula) {
            dispatch(openEditFormula());
          } else {
            dispatch(closeEditFormula());
          }
        }  
    })
})

  useEffect(() => {
    dispatch(menuActions.setActiveMenu("roadmaps"));
  }, [dispatch]);

  

  
  async function onConfirmDelete(result) {
    if (result) {

        if (recordtype === 'section') {
            await dispatch(deleteSections(recordkey))
                .unwrap()
                  dispatch(fetchAllSections());
                  dispatch(closeDeleteModal());
        }

        if (recordtype === 'row') {
          await dispatch(deleteRows(recordkey))
              .unwrap()
                  dispatch(fetchAllRows());
                  dispatch(closeDeleteModal());
      }
    }
  }


  if ( !indent || !roadmap || !baselinedelta) {
    return <Spinner/>;
  }


  let minYear=""
  let maxYear=""
  if (roadmap.results.length !== 0) {
    minYear = (roadmap.results[0]).min_year
    maxYear = (roadmap.results[0]).max_year
  } else {
    minYear = new Date().getFullYear() 
    maxYear = new Date().getFullYear()+5 
  }

  
  function renderroadmapspage() {
    return (
<roadmapdatasets.Provider value={{ baselinedelta, minYear, maxYear}}>
    <Fragment>
      <main className="has-navbar-fixed-top" style={{marginTop: "3.5em", userSelect: "none"}}>   
        <section style={{paddingLeft: "10%", paddingRight: "10%", marginTop: "2em"}}>
        <Header/>

        <Roadmap 
            gantt={gantt}
            indent={indent}
        />

        {showSectionsModal && <SectionsModal/>}
        {isEditRowsModalOpen && <SectionsModalEditRows/>}
        {showSectionsGraphModal && <SectionsGraphModal/>}
        {showSectionsChartEditModal && <SectionsModalEditCharts/>}
        {showAddSectionsModal && <SectionsModalAddSection/>}

        <PopupModal/>

        </section>
      </main>

    <ConfirmDeleteBox onConfirmDelete={onConfirmDelete}/>
    </Fragment>
    </roadmapdatasets.Provider>
    )
  }

  return (
    (isDataLoading?<Spinner/>:renderroadmapspage())
  )
}


export { Roadmaps };
