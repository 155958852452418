import api from "../../api/tenants/currentuser";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const currentuserAdapter = createEntityAdapter({
  selectId: (currentuser) => currentuser.id,
  //sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = currentuserAdapter.getInitialState({status: "idle"});

export const fetchAllCurrentuser = createAsyncThunk("currentuser/fetch", () => api.fetchAllCurrentuser());

export const updateCurrentuser = createAsyncThunk("currentuser/update", api.updateCurrentuser);

export const createCurrentuser = createAsyncThunk("currentuser/create", api.createCurrentuser);

export const deleteCurrentuser = createAsyncThunk("currentuser/delete", api.deleteCurrentuser);

const currentuserSlice = createSlice({
  name: "currentuser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCurrentuser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCurrentuser.fulfilled, (state, { payload }) => {
        currentuserAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateCurrentuser.fulfilled, currentuserAdapter.updateOne)
      .addCase(deleteCurrentuser.fulfilled, currentuserAdapter.removeOne)
      .addCase(createCurrentuser.fulfilled, currentuserAdapter.addOne);
  },
});

export const {} = currentuserSlice.actions;

export default currentuserSlice.reducer;

const selectState = (state) => state.currentuser;

export const {
  selectAll: selectCurrentuser,
  selectById: selectCurrentuserById,
} = currentuserAdapter.getSelectors(selectState);

export const selectCurrentuserStatus = createSelector(
  selectState,
  (state) => state.status
);
