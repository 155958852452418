import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./common/css/masterstyles.css";
import PageTitle from "./common/PageTitle";
import { menuActions } from "../redux/actions/menu";
import BaseService from "../services/BaseService";

function Support() {
  const currentTenant = useSelector((state) => state.tenants.currentTenant);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(menuActions.setActiveMenu("support"));
  }, [dispatch]);

  console.log(BaseService.baseServer)
  return (
    <main className="has-navbar-fixed-top" style={{marginTop: "3.5em"}}>
    <section style={{paddingLeft: "10%", paddingRight: "10%", marginTop: "2em"}}>
            <nav className="level pb-0 mb-0 pt-5">
                      <div className="level-left">
                          <div className="level-item">
                              <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "28px", lineHeight: "22px", paddingTop: "35px"}}>
                                  <div className="pr-1">
                                  <img
                                      style={{marginTop: "auto", marginBottom: "auto"}}
                                      className="menuimage mb-3"
                                      alt="Strat2gy Logo"
                                      src="https://storage.googleapis.com/endless-air-403516_growthbasket/images/Agendi%20Capital/strat2gy.png"
                                    />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </nav>
                  
                  <hr className="pt-0 mt-0 pb-0 mb-5" style={{borderTop: "2px solid lightgrey"}} />
                  <span style={{fontSize:"smaller", fontWeight:'600'}}><span>&#169;</span>2024 STRAT2GY</span><br/>
                  <p> Version: 1.17.12 [ALPHA]</p>
                  <p> Licensed User: {currentTenant?currentTenant.name:'Undefined'}</p>

                  <p className="mt-5 mb-1" style={{fontWeight:600}}>SUPPORT</p>
                  <i className="fas fa-envelope mr-4" style={{fontSize:'smaller'}}/>contact@strat2gy.com<br/>
                  <i className="fas fa-phone-alt mr-4" style={{fontSize:'smaller'}}/>+1 778 222 9505<br/>
                  <i className="fas fa-clock mr-4" style={{fontSize:'smaller'}}/>10:00am to 5:00pm EST/EDT

    </section>
  </main>

  );
}

export { Support };
