import api from "../../api/organization/organization";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

const organizationAdapter = createEntityAdapter({
  selectId: (person) => person.pk,
  //sortComparer: (a, b) => a.secondname.localeCompare(b.secondname),
});

const initialState = organizationAdapter.getInitialState({status: "idle"});

export const fetchAllOrganization = createAsyncThunk("organization/fetch", () => api.fetchAllOrganization());

export const updateOrganization = createAsyncThunk("organization/update", api.updateOrganization);

export const createOrganization = createAsyncThunk("organization/create", api.createOrganization);

export const deleteOrganization = createAsyncThunk("organization/delete", api.deleteOrganization);

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrganization.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllOrganization.fulfilled, (state, { payload }) => {
        organizationAdapter.setAll(state, payload);
        state.status = "idle";
      })
      .addCase(updateOrganization.fulfilled, organizationAdapter.updateOne)
      .addCase(deleteOrganization.fulfilled, organizationAdapter.removeOne)
      .addCase(createOrganization.fulfilled, organizationAdapter.addOne);
  },
});

export const {} = organizationSlice.actions;

export default organizationSlice.reducer;

const selectState = (state) => state.organization;

export const {
  selectAll: selectOrganization,
  selectById: selectOrganizationById,
} = organizationAdapter.getSelectors(selectState);

export const selectOrganizationStatus = createSelector(
  selectState,
  (state) => state.status
);
