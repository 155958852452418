import "regenerator-runtime/runtime.js";
import axios from "axios";
import { authConfig } from "./helpers";
import BaseService from "./BaseService";

class UsersService extends BaseService {
  listUrl() {
    return `${this.baseUrl}/users`;
  }

  async getCurrentUser() {
    const url = this.listUrl() + "/current";
    const response = await axios.get(url, authConfig());
    return response.data;
  }

  async changePassword(id, data) {
    const url = this.detailUrl(id) + "/change-password";
    const response = await axios.post(url, data, authConfig());
    return response.data;
  }

  isAdmin(user) {
    return user && user.role === 2;
  }

  canWrite(user) {
    return user && user.role >= 1;
  }
}

export const usersService = new UsersService();
