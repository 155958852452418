import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { usersService } from "../services/UsersService";
import { UserManagement } from "./settings/UserManagement";
import { tenantsService } from "../services/TenantsService";
import { fetchAllTenants } from "../redux/toolkit/features/tenants";
import { fetchAllUsers } from "../redux/toolkit/features/tenants/users";
import { fetchAllCurrentuser } from "../redux/toolkit/features/tenants/currentuser";


function Users() {
  const currentUser = Object.values(useSelector((state) =>  state.currentuser.entities));
  const currentTenant = Object.values(useSelector((state) =>  state.tenants.entities));
  const listResponse = useSelector((state) => state.users.listResponse);
  const [showModal, setShowModal] = useState(false)
  const isAdmin = usersService.isAdmin(currentUser);
  const [selectedTab, setSelectedTab] = useState("company");
  const [tenant,setTenant] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllTenants());
    dispatch(fetchAllUsers());
    dispatch(fetchAllCurrentuser());
}, []);

  console.log("User List", currentUser, currentTenant)

  async function fetchTenant() {
    const tenant = await tenantsService.getTenant();
    setTenant(tenant);  
  }


  function toggleModal(open) {
    setShowModal(open)
  }

  async function updateEmailOnChange(domain, key) {
    await tenantsService.patch(key, {emaildomain:domain});
    await fetchTenant();
  }


  if (!isAdmin) {
    return null;
  }
  return (
    <main className="has-navbar-fixed-top" style={{marginTop: "3.5em"}}>
    <section style={{paddingLeft: "10%", paddingRight: "10%", marginTop: "2em"}}>
            <nav className="level pb-0 mb-0 pt-5">
                      <div className="level-left">
                          <div className="level-item">
                              <div style={{fontWeight: "600", fontFamily: "Gotham Extra", fontSize: "32px", lineHeight: "22px", paddingTop: "35px"}}>
                                  <div className="pr-1">
                                      USERS ({listResponse.count})<br/><br/>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </nav>
                  
                  <hr className="pt-0 mt-0 pb-0 mb-5" style={{borderTop: "2px solid lightgrey"}} />



        

          <UserManagement 
          tenantname={currentTenant}
          user={currentUser}
          tenant={tenant} />


      </section>
    </main>
  );
}

export { Users };
